import { useState, useEffect, useContext, useMemo, useCallback, Dispatch, SetStateAction } from "react";
import { AddRemove, ErrorContext } from "../contexts/Error/ErrorContext";
import useApi, { ExecOptions } from "../api/hooks/useApi";
import { anyOf } from "../utils/references";
import { UserContext } from "../contexts/User/UserContext";
import { AbortControllerRef } from "../api/api";
import { l } from "../utils/log";
import { RequestStatus } from "../api/constants/apiStatus";
import { getSplitErrorsBySource } from "../contexts/Error/ErrorProvider";

export enum AuthStyle {
  NO_AUTH_REFETCH = "NO_AUTH_REFETCH",
  NO_AUTH_NULLIFY = "NO_AUTH_NULLIFY",
  NO_AUTH_KEEP = "NO_AUTH_KEEP",
}

export type UseResultReturnBase<
  F extends (arg0: { params: any; abortRef?: AbortControllerRef }) => any,
  T extends (data: Awaited<ReturnType<F>>) => any
> = {
  result: ReturnType<T> | null;
} & {
  requestIfDifferentParams: Dispatch<SetStateAction<Parameters<F>[0]["params"] | null>>;
  request: (params: Parameters<F>[0]["params"]) => void;
  params: Parameters<F>[0]["params"] | null;
  isPending: boolean;
  apiStatus: RequestStatus;
  clearApi: () => void;
  attemptRecoveryFromError: () => void;
  invalidated: boolean;
  finalNs: string;
};

export type ABTest<T> = (a: T | null, b: T | null) => boolean;

const ns = 'useResultBase';

function useResultBase<
  F extends (arg0: { params: any; abortRef?: AbortControllerRef }) => any,
  T extends (data: Awaited<ReturnType<F>>) => any
>(
  fetchResult: F,
  transform: T,
  paramsAreSame: ABTest<Parameters<F>[0]["params"]>,
  {
    authStyle,
    initialParams = null,
    callerNs = ns,
    execOptions = undefined,
  }: {
    authStyle: AuthStyle;
    initialParams?: Parameters<F>[0]["params"] | null;
    callerNs?: string;
    execOptions?: ExecOptions;
  }
) {
  const [params, setParams] = useState<Parameters<F>[0]["params"] | null>(initialParams);
  const finalNs = `${ns}:${callerNs}::${typeof params === "object" ? JSON.stringify(params) : String(params)}`;
  const log = l([finalNs]);
  const [result, setResult] = useState<ReturnType<T> | null>(null);
  const [invalidated, setInvalidated] = useState<boolean>(false);
  const { isJwtActive, transactionInProgress } = useContext(UserContext);
  const [wasJwtActive, setWasJwtActive] = useState<boolean>(isJwtActive);
  const { sourcedErrors, addOrRemoveSourcedError, removeFirstFromSource } = useContext(ErrorContext);

  const errorsFromHere = useMemo(
    () => {
      const [selected,] = getSplitErrorsBySource(sourcedErrors, finalNs)
      return selected;
    },
    [sourcedErrors, finalNs]
  );

  const setData = useCallback(
    (data: Awaited<ReturnType<F>>) => {
      log("setting data");
      setResult(transform(data));
      setInvalidated(false);
    },
    [transform]
  );

  const {
    exec,
    data,
    error,
    isIdle,
    isError,
    isPending,
    isSuccess,
    apiStatus,
    clearApi,
    clearHook,
  } = useApi(fetchResult, setData, `${ns}:${callerNs}`);

  // Main fetch effect
  useEffect(() => {
    const exitEarly = anyOf({
      NOT_params: !params,
      NOT_isIdle: !isIdle,
      transactionInProgress,
      isPending,
      errorCreatedByMe: errorsFromHere.length,
      requiresAuth_and_NOT_user: authStyle !== AuthStyle.NO_AUTH_REFETCH && !isJwtActive,
      hasResult_NOT_beenInvalidatedYet: result !== null && !invalidated,
    });

    if (exitEarly.bool) return;
    exec(params!, execOptions);
  }, [
    params,
    isIdle,
    isJwtActive,
    invalidated,
    exec,
    execOptions,
    result,
    errorsFromHere,
    authStyle,
    transactionInProgress,
  ]);

  // Authentication state changes
  useEffect(() => {
    if (wasJwtActive === isJwtActive) return;
    if (!isJwtActive && result !== null) {
      switch (authStyle) {
        case AuthStyle.NO_AUTH_REFETCH:
          setInvalidated(true);
          break;
        case AuthStyle.NO_AUTH_NULLIFY:
          setResult(null);
          setInvalidated(true);
          break;
        case AuthStyle.NO_AUTH_KEEP:
          break;
      }
    }
    setWasJwtActive(isJwtActive);
  }, [isJwtActive, wasJwtActive, result, authStyle]);

  // Error handling
  useEffect(() => {
    if (!isError) {
      removeFirstFromSource(finalNs);
    } else if (error && errorsFromHere.findIndex(({ timedError }) => timedError.error === error) === -1) {
      addOrRemoveSourcedError(finalNs, { error, date: new Date() }, AddRemove.add);
    }
  }, [error, isError, addOrRemoveSourcedError, removeFirstFromSource, errorsFromHere]);

  // Clear on success
  useEffect(() => {
    if (isSuccess && data) clearHook();
  }, [data, isSuccess, clearHook]);

  // Parameter update
  const updateParams = useCallback(
    (newParams: Parameters<F>[0]["params"]) => {
      if (paramsAreSame(params, newParams)) return;
      setParams(newParams);
      setResult(null);
    },
    [params, paramsAreSame]
  );

  // Invalidation
  useEffect(() => {
    if (invalidated) {
      clearApi();
      setInvalidated(false);
    }
  }, [invalidated, clearApi]);

  return {
    result,
    requestIfDifferentParams: updateParams,
    request: (p: Parameters<F>[0]["params"]) => {
      setParams(p);
      setResult(null);
      setInvalidated(true);
    },
    params,
    isPending,
    apiStatus,
    clearApi,
    attemptRecoveryFromError: () => {
      clearApi();
      removeFirstFromSource(finalNs);
    },
    invalidated,
    finalNs,
  };
}

export default useResultBase;
import React, {
  useContext,
  useState,
  useCallback,
  ReactNode,
} from 'react';
import { NamespacedPrioritizedLink, DEFAULT_PRIORITY, LinksDisplayRegion, NamespacedLinksDictionary, PreviousRelacements, QuickLink, QuickLinksContext, RemoveLinkProps, ReplaceLinkProps, UnfilledAction, UnfilledRequest } from './QuickLinksContext';
import { l } from '../../utils/log';

const log = l("QuickLinksProvider");

/*
 * Concurrency Problem:
 * -------------------
 * When replacing links (A -> B -> C), we face a race condition:
 * 1. Replace A with B (stores B in previousReplacements[A])
 * 2. Replace A with C (should remove both A and B)
 * 3. If B gets added after step 2, it will appear alongside C
 *
 * Current Limitation:
 * We don't queue unfulfilled replacements for previousReplacements to avoid
 * blocking valid additions, but this allows inconsistent states under concurrency.
 *
 * Recommended Solution:
 * Implement a versioned replacement chain:
 * {
 *   originalId: LinkableElementId;
 *   currentId: LinkableElementId;
 *   version: number;
 *   chain: Array<{
 *     id: LinkableElementId;
 *     timestamp: number;
 *   }>;
 * }
 *
 * This would:
 * 1. Track complete replacement history
 * 2. Maintain proper ordering
 * 3. Handle concurrent operations via versioning
 * 4. Enable cleanup of stale replacements
 */

// Initialize the dictionary with an empty array for each namespace
const initializeNamespacedLinksDictionary = (): NamespacedLinksDictionary => {
  log("initializeNamespacedLinksDictionary");
  const initialState: NamespacedLinksDictionary = {} as NamespacedLinksDictionary;
  Object.values(LinksDisplayRegion).forEach((namespace) => {
    initialState[namespace] = [];
  });
  return initialState;
};

const hasPriority = (link: QuickLink): link is QuickLink & { actionPriority: number; } => {
  return link.actionPriority !== undefined
}
const withPriority = (link: QuickLink) => {
  return hasPriority(link)
    ? link
    : { ...link, actionPriority: DEFAULT_PRIORITY };
}

// Helper function to insert a link into the sorted array
const insertLinkSorted = (highToLowPriorityLinks: QuickLink[], newLink: QuickLink): QuickLink[] => {
  const indexOfLinkWithLowerPriority = highToLowPriorityLinks.findIndex((link) => link.priority < newLink.priority);
  if (indexOfLinkWithLowerPriority === -1) {
    return [...highToLowPriorityLinks, newLink];
  }
  return [
    ...highToLowPriorityLinks.slice(0, indexOfLinkWithLowerPriority),
    newLink,
    ...highToLowPriorityLinks.slice(indexOfLinkWithLowerPriority),
  ];
};

type QuickLinksState = {
  namespacedLinksDictionary: NamespacedLinksDictionary;
  unfilledRequests: UnfilledRequest[];
  previousReplacements: PreviousRelacements;
}

const inferNextState = (
  prevState: QuickLinksState,
  {
    link,
    namespace = LinksDisplayRegion.common,
    priority = 0
  }: NamespacedPrioritizedLink
) => {
  const { namespacedLinksDictionary, unfilledRequests, previousReplacements } = prevState;
  log("Infer next state START: ", prevState);
  const idx = unfilledRequests.findIndex((ur) => ur.id === link.id && ur.namespace === namespace);
  if (idx !== -1) {
    log("unfilled request FOUND for", namespace, link.id);
    const unfilledRequest = unfilledRequests[idx];
    if (unfilledRequest.actionPriority > priority) {
      log("unfilled HIGHER PRIORITY", namespace, link.id);
      return prevState; // skip adding if marked for removal by another call with higher priority
    }
    log("unfilled lower PRIORITY", link.id);
  }
  return {
    namespacedLinksDictionary: {
      ...namespacedLinksDictionary,
      [namespace]: insertLinkSorted(namespacedLinksDictionary[namespace] || [], withPriority(link)), // Update the specific namespace's links
    },
    unfilledRequests: idx !== -1 ? unfilledRequests.splice(idx, 1): unfilledRequests,
    previousReplacements,
  };
}

export const QuickLinksProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, setState] = useState<QuickLinksState>({
    namespacedLinksDictionary: initializeNamespacedLinksDictionary(),
    unfilledRequests: [],
    previousReplacements: {} as PreviousRelacements,
  });

  // Add multiple links in a single update
  const addLinks = useCallback((nsPLinks: NamespacedPrioritizedLink[]) => {
    setState((prevState) => {
      return nsPLinks.reduce(inferNextState, prevState);
    });
  }, []);

  // Add link logic
  const addLink = useCallback((nsPLink: NamespacedPrioritizedLink) => {
    setState((prevState) => {
      return inferNextState(prevState, nsPLink);
    });
  }, []);

  // Replace link logic, reuse add logic if link is not found
  const replaceLink = useCallback(({ newLink, oldLinkId, namespace = LinksDisplayRegion.common }: ReplaceLinkProps) => {
    setState((prevState) => {
      const { namespacedLinksDictionary, unfilledRequests, previousReplacements } = prevState;
      log("replaceLink: prevState", prevState.namespacedLinksDictionary);
      log("replaceLink: newLink", newLink);
      log("replaceLink: oldLinkId", oldLinkId);
      log("replaceLink: namespace", namespace);

      log("replaceLink: prevLinks", namespacedLinksDictionary);
      const namespaceLinks = namespacedLinksDictionary[namespace] || [];
      const index = namespaceLinks.findIndex((link) => link.id === oldLinkId);

      // When replacing an id (say id A), we also want to replace the id's that A was replaced by
      const previousReplacementsForOldId = previousReplacements[oldLinkId] || [];
      // Fix: Changed !== to === to properly filter out replaced links
      const nsLinksWithoutPreviousReplacementsForOldId = namespaceLinks.filter(link =>
        previousReplacementsForOldId.indexOf(link.id) === -1 && link.id !== oldLinkId
      );

      log("replaceLink: previousReplacementsForOldId", previousReplacementsForOldId);

      const nextPreviousReplacementsForOldId = (previousReplacementsForOldId.indexOf(newLink.id) === -1)
        ? [...previousReplacementsForOldId, newLink.id]
        : previousReplacementsForOldId;

      const nextPreviousReplacements = {
        ...previousReplacements,
        [oldLinkId]: nextPreviousReplacementsForOldId
      };

      log("index", index);
      if (index === -1) {
        // If no link with the given id is found, use add logic
        log("replaceLink: Warning: Trying to replace quick link before it's been added");
        log("replaceLink: Warning: ...Existing: ", Object.keys(namespacedLinksDictionary));
        log("replaceLink: Warning: Suggestion, make sure to call removeLink with the correct namespace param");

        const isThereAnUnfilledRequestForRemoval = -1 !== unfilledRequests.findIndex(r => oldLinkId === r.id);
        return {
          namespacedLinksDictionary: {
            ...namespacedLinksDictionary,
            [namespace]: insertLinkSorted(nsLinksWithoutPreviousReplacementsForOldId, newLink),
          },
          unfilledRequests: isThereAnUnfilledRequestForRemoval
            ? unfilledRequests
            : [
                ...unfilledRequests,
                {
                  id: oldLinkId,
                  namespace,
                  actionPriority: newLink.actionPriority || DEFAULT_PRIORITY,
                  action: UnfilledAction.removeLink
                },
            ],
          previousReplacements: nextPreviousReplacements,
        };
      }

      // Replace the existing link at the found index with the new link
      const updatedLinks = insertLinkSorted(nsLinksWithoutPreviousReplacementsForOldId, newLink);

      log("replaceLink: updatedLinks", index);
      return {
        namespacedLinksDictionary: {
          ...namespacedLinksDictionary,
          [namespace]: updatedLinks,
        },
        unfilledRequests,
        previousReplacements: nextPreviousReplacements,
      };
    });
  }, []);

  // Remove link logic
  const removeLink = useCallback(({ id, actionPriority = DEFAULT_PRIORITY, namespace = LinksDisplayRegion.common }: RemoveLinkProps) => {
    setState((prevState) => {
      log("removeLink: prevState", prevState)
      log("removeLink: CAALLL to REMOVE", id, actionPriority, namespace);
      const { namespacedLinksDictionary, unfilledRequests, previousReplacements } = prevState;
      if (!namespacedLinksDictionary[namespace] || namespacedLinksDictionary[namespace].length <= 0) {
        if (!namespacedLinksDictionary[namespace]) {
          log("removeLink: Warning: Trying to remove unexistent namespace quick link");
          log("removeLink: Warning: ...Existing: ", Object.keys(namespacedLinksDictionary));
          log("removeLink: Warning: ...Arg Namespace: ", namespace);
          log("removeLink: Warning: Suggestion, make sure to call removeLink with the correct namespace param");
        }
        return {
          namespacedLinksDictionary,
          unfilledRequests: [...unfilledRequests, { id, namespace, actionPriority, action: UnfilledAction.removeLink}],
          previousReplacements,
        };
      }
      return {
        namespacedLinksDictionary: {
          ...namespacedLinksDictionary,
          [namespace]: (namespacedLinksDictionary[namespace] || []).filter((link) => link.id !== id),
        },
        unfilledRequests,
        previousReplacements,
      };
    });
  }, []);

  return (
    <QuickLinksContext.Provider value={{ addLink, addLinks, replaceLink, removeLink, state }}>
      {children}
    </QuickLinksContext.Provider>
  );
};

export const useQuickLinks = () => {
  const context = useContext(QuickLinksContext);
  if (!context) {
    throw new Error('useQuickLinks must be used within a QuickLinksProvider');
  }
  const { state: { namespacedLinksDictionary }, addLink, addLinks, removeLink, replaceLink } = context;

  return { links: namespacedLinksDictionary, addLink, addLinks, removeLink, replaceLink };
};
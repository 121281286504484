import React from 'react';
import Pagination from '../Pagination';
import SearchQueryFilters from '../SearchQueryFilters';
import './index.scss';
import { useTranslation } from 'react-i18next';
import SortingControls from '../SortingControls';
import useBookSearch from '../../hooks/useBookSearch';
import { useStoragePreference } from '../../contexts/Preferences/PreferencesProvider';
import { PreferenceKey } from '../../types/preferences';

type ResultsControlsProps = {
  "aria-controls": string;
};

const ResultsControls: React.FC<ResultsControlsProps> = ({
  "aria-controls": ariaControls,
}) => {
  const { t } = useTranslation(['common']);

  const { preference: isLimitToFiltersOnly } = useStoragePreference<boolean>(PreferenceKey.LIMIT_TO_FILTERS_ONLY);

  useBookSearch("ResultsControls");

  return (
    <div
      className="ResultsControls"
      aria-label={`${t('Results controls')}`}
      aria-controls={ariaControls}
    >
      {(!isLimitToFiltersOnly && (<Pagination withinToolbar={false} />)) || null}
      {(!isLimitToFiltersOnly && (<SortingControls withinToolbar={false} />)) || null}
      <SearchQueryFilters withinToolbar={false} />
    </div>
  );
};

export default ResultsControls;

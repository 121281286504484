import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { TabIndex } from '../../types/accessibility';

interface GoBackLinkProps {
  excludePatterns?: RegExp[];
}

const GoBackLink: React.FC<GoBackLinkProps> = ({ excludePatterns = [] }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(['common']);

  // Don't show if no previous page or if path matches any exclude pattern
  if (!location.key || excludePatterns.some(pattern => pattern.test(location.pathname))) {
    return null;
  }

  return (
    <a
      className="visually-hidden"
      onClick={() => navigate(-1)}
      tabIndex={TabIndex.reachablePriorityLowest}
      aria-label={t('Go back')}
    >
      ← {t('Go back')}
    </a>
  );
};

export default GoBackLink;

import React, { useContext, useEffect, useMemo, useState } from "react";
import "./index.scss";
import { Pagination, RemoteServiceId } from "../../api/implementations/types";
import { getNewWishes } from "../../api/implementations/booksApi";
import Books from "../Books";
import { UserContext } from "../../contexts/User/UserContext";
import { useTranslation } from "react-i18next";
import { l } from "../../utils/log";
import { Book } from "../../types/book";
import { getBookCardId } from "../BookCard";
import useErrorManager from "../../hooks/useErrorManager";
import useFullResult from "../../hooks/useFullResult";
import { BypassOption, ExecOptions } from "../../api/hooks/useApi";
import { AuthStyle } from "../../hooks/useResultBase";

const paginationsAreSame = (a: Pagination | null, b: Pagination | null) => {
  return (
    (a === null && b === null) ||
    (b !== null && a !== null && a.start === b.start && a.count === b.count)
  );
};

const filterUnwished = (wishes: Book[], filterOutCodes: CodeOriginId[]) => {
  return wishes.filter(
    (wish) =>
      -1 ===
      filterOutCodes.findIndex(
        ({ code, origin }) => wish.code === code && wish.origin === origin
      )
  );
};

type CodeOriginId = { code: string; origin: RemoteServiceId };

const showCount = 24;
const ns = "WishItemList";
const log = l(ns);

const execOptions: ExecOptions = {
  bypass: BypassOption.cache,
};
const smartFetchCount = showCount + 1;
const initialParams = {
  start: 0,
  count: smartFetchCount,
};

const WishItemList: React.FC = () => {
  const { t } = useTranslation(["common"]);
  const {
    params,
    result,
    invalidated,
    requestIfDifferentParams: setParams,
    isPending,
    apiStatus,
    attemptRecoveryFromError,
  } = useFullResult(getNewWishes, paginationsAreSame, {
    authStyle: AuthStyle.NO_AUTH_NULLIFY,
    initialParams,
    callerNs: ns,
    execOptions,
  });

  useErrorManager(ns, attemptRecoveryFromError);

  const [filterOutCodes, setFilterOutCodes] = useState<CodeOriginId[]>([]);
  const [cumulatedItems, setCumulatedItems] = useState<Book[] | null>(null);
  const start = params?.start || 0;

  const [nextRequestWillReturnEmpty, setNextRequestWillReturnEmpty] = useState<boolean>(false);

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (!result) {
      if (invalidated) {
        setCumulatedItems(null);
      }
      return;
    }
    const items = (result && result["books"]) || null;
    log("SHOW COUNT", showCount);
    if (result.totalCount <= (start + showCount)) {
      setNextRequestWillReturnEmpty(true);
    }

    setCumulatedItems((prevState) => {
      const prevItems = prevState !== null ? prevState : [];
      const newItems = items.slice(0, showCount);
      const uniqueNewItems = newItems.filter(
        (newItem) => !prevItems.some((existingItem) => existingItem.code === newItem.code && existingItem.origin === newItem.origin)
      );
      return filterUnwished([...prevItems, ...uniqueNewItems], filterOutCodes);
    });

  }, [result, invalidated, filterOutCodes, start]);

  log("Cum", cumulatedItems);

  const filtered = useMemo(
    () => (cumulatedItems ? filterUnwished(cumulatedItems, filterOutCodes) : []),
    [cumulatedItems, filterOutCodes]
  );

  log("Filtered", filtered);

  return (
    <section className="WishItemList">
      <Books
        title={t("Wishes")}
        modifier="wishlist"
        noBooksMessage={user
          ? t("No wishes yet. Whenever you add books to your wishlist, they will show up here.")
          : `${t("Your wishes")} ${t("will show when you log in.")}`}
        books={filtered.map((w) => ({ ...w, wished: true }))}
        isPending={Boolean(isPending || (!cumulatedItems && user))}
        apiStatus={apiStatus}
        onWishChange={({ nextIsWished, ...identifier }) => {
          // we are doing something funky here, we need to place the focus before
          // we remove the currently focused element, otherwise by the time
            log("on wish changed ");
          if (nextIsWished) return;
          setTimeout(() => {
            requestAnimationFrame(() => {
              const nextBookInList = filtered.find((_, index, arr) => Boolean(arr[index - 1]?.code === identifier.code));
              const nextBook = !nextBookInList
                ? (filtered.length > 1
                  ? filtered[filtered.length -2]
                  : undefined
                )
                : nextBookInList;
                log("nextBook in list ", nextBookInList);
              if (!nextBook) {
                log("NO next book in list");
                return;
              }
              const nextBookTitleElement = window.document.querySelector<HTMLHeadingElement>(`#${getBookCardId(nextBook)} h3>a`)
                log("next book in list el", nextBookTitleElement);
              nextBookTitleElement?.focus()
            });
            setFilterOutCodes((prevState) => [...prevState, identifier])
          }, 4000);
        }}
        containerId={"wishlist-main-section"}
      />
      {(cumulatedItems && (
        !nextRequestWillReturnEmpty ? (
          <button
            className="WishItemList__More"
            onClick={() => {
              setParams({
                start: start + showCount,
                count: smartFetchCount, // fetch one more to know if more
              });
            }}
          >
            {`${t("Load more")} ${t("wishes")}`}
          </button>
        ) : (
          <p className="WishItemList__EOList">
            ... {`${t("End of your")} ${t("wishlist")}`}
          </p>
        )
      )) || null}
    </section>
  );
};

export default WishItemList;

import { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { UserContext } from "../contexts/User/UserContext";
import { AddRemove, ErrorContext } from "../contexts/Error/ErrorContext";
import authenticate from "../api/implementations/userApi";
import useApi, { BypassOption } from "../api/hooks/useApi";
import { anyOf } from "../utils/references";
import { User } from "../types/user";
import { useClientInfo } from "./useClientInfo";
import { getSplitErrorsBySource } from "../contexts/Error/ErrorProvider";

const ns = 'useAuth';

const useAuth = (callerNs: string = ns) => {
  const finalNs = `${ns}:${callerNs}`;
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const client = useClientInfo();
  const [prevent, setPrevent] = useState<boolean>(false);
  const [isTriggerSubmitWhenAvailable, setIsTriggerSubmitWhenAvailable] = useState(true);
  const {
    user,
    setUser: setStorageUser,
    setTransactionInProgress,
    transactionInProgress
  } = useContext(UserContext);
  const {
    addOrRemoveSourcedError,
    removeFirstFromSource,
    sourcedErrors
  } = useContext(ErrorContext);

  const errorsFromHere = useMemo(
    () => {
      const [selected,] = getSplitErrorsBySource(sourcedErrors, finalNs)
      return selected;
    },
    [sourcedErrors, finalNs]
  );

  const setData = useCallback((data: { user: User; authorization?: string; } | null) => {
    setStorageUser(data && data.user);
    setIsTriggerSubmitWhenAvailable(false);
  }, [setStorageUser, setIsTriggerSubmitWhenAvailable]);

  const {
    exec: fetchUser,
    data,
    error,
    isIdle,
    isError,
    isSuccess,
    isPending,
    apiStatus,
    clearApi,
  } = useApi(authenticate, setData, finalNs);

  useEffect(() => {
    const exitEarly = anyOf({
      NOT_TriggerSubmitWhenAvailable: !isTriggerSubmitWhenAvailable,
      user,
      NOT_Username: !username,
      NOT_Password: !password,
      NOT_isIdle: !isIdle,
      prevented: prevent,
      transactionInProgress,
      errorCreatedByMe: errorsFromHere.length,
    });

    if (exitEarly.bool) {
      return;
    }

    setTransactionInProgress(true);
    fetchUser({ username, password, client }, {
      cache: { keepAliveFor: 0 },
      bypass: BypassOption.all
    });
  }, [
    user,
    username,
    password,
    client,
    prevent,
    isPending,
    fetchUser,
    isTriggerSubmitWhenAvailable,
    transactionInProgress,
    setTransactionInProgress,
    isIdle,
    errorsFromHere,
  ]);

  useEffect(() => {
    if (!isError && errorsFromHere.length > 0) {
      removeFirstFromSource(finalNs);
    } else if (error && errorsFromHere.findIndex(({ timedError }) => timedError.error === error) === -1) {
      addOrRemoveSourcedError(finalNs, {
        error,
        date: new Date(),
      }, AddRemove.add);
      setTransactionInProgress(false);
      setIsTriggerSubmitWhenAvailable(false);
    }
  }, [error, isError, addOrRemoveSourcedError, setTransactionInProgress, clearApi]);

  useEffect(() => {
    if (!isSuccess) return;
    clearApi();
  }, [isSuccess, data, setStorageUser, clearApi]);

  const triggerSubmitWhenAvailable = useCallback(() => {
    setIsTriggerSubmitWhenAvailable(true);
  }, [setIsTriggerSubmitWhenAvailable]);

  const triggerSubmit = useCallback(() => {
    setPrevent(false);
    triggerSubmitWhenAvailable();
  }, [setIsTriggerSubmitWhenAvailable, setPrevent]);

  return {
    user,
    triggerSubmitWhenAvailable,
    triggerSubmit,
    apiStatus,
    isPending,
    isTransactionInProgress: transactionInProgress,
    setUsername,
    setPassword,
    disconnect: () => {
      setStorageUser(null);
      setUsername("");
      setPassword("");
    },
    username,
    password,
    setPrevent,
    prevent,
    attemptRecoveryFromError: () => {
      removeFirstFromSource(finalNs);
      clearApi();
    },
    finalNs,
  };
};

export default useAuth;

import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import Loading from "../Loading";
import { ErrorToDismissAction } from "../../contexts/Error/ErrorContext";
import useErrorManager from "../../hooks/useErrorManager";
import "./index.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { MdAccountCircle } from "react-icons/md";
import { TabIndex } from "../../types/accessibility";
import { l } from "../../utils/log";
import { NotificationPriority, NotificationType } from "../../contexts/Notification/NotificationContext";
import { useNotifications } from "../../contexts/Notification/NotificationProvider";
import { RequestStatus } from "../../api/constants/apiStatus";
import { notification_auth_disconnected, notification_auth_error, notification_auth_loading, notification_auth_success, notification_book_loaded, notification_book_loading, notification_books_loading } from "../../utils/notifications";
import TextInput from "../TextInput";
import { LinkableElementId, LinkPriority, LinksDisplayRegion } from "../../contexts/QuickLinks/QuickLinksContext";
import { useAddQuickLink } from "../../hooks/useAddQuickLink";
import Button from "../Button";

const ns = "Login";
const log = l(ns);

const passwordInputId = "outlined-password-input";
const loginInputId = "outlined-username-input";
const loginWidgetTitleId = "login-widget-title";

const loginWidgetTitleLink = {
  id: LinkableElementId.loginWidget,
  href: `#${loginWidgetTitleId}`,
  label: 'Go to login widget',
  priority: LinkPriority.high,
  actionPriority: 1,
};

const addInstructions = {
  link: loginWidgetTitleLink,
  namespace: LinksDisplayRegion.pageComponentTop,
};

const Login: React.FC = function Login() {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const location = useLocation();
  const isAccountRoute = location.pathname.includes('/account');
  const {
    disconnect,
    user,
    username,
    password,
    setUsername,
    setPassword,
    isPending,
    apiStatus,
    isTransactionInProgress,
    triggerSubmit,
    setPrevent,
    prevent: isAnyInputFocused,
    attemptRecoveryFromError,
  } = useAuth(ns);

  const errorToDismissActionOverride = useCallback((errorToDismissAction: ErrorToDismissAction) => {
    log("error to action", errorToDismissAction);
    if (errorToDismissAction.timedError.error.message === "Request failed with status code 400") {
      return {
        ...errorToDismissAction,
        source: ns,
        message: "Bad credentials",
        dismissAction: () => {
          errorToDismissAction.dismissAction && errorToDismissAction.dismissAction();
          setTimeout(() => {
            window.document.getElementById(loginInputId)?.focus();
          }, 300);
        },
      };
    }
    return errorToDismissAction;
  }, [loginInputId]);

  useErrorManager(ns, attemptRecoveryFromError, errorToDismissActionOverride);

  const { addMessages } = useNotifications();

  log("Api Status", apiStatus);
  useEffect(() => {
   if (isPending) {
      log(notification_auth_loading);
      addMessages([{
        ...notification_auth_loading,
        message: t(notification_auth_loading.id),
        priority: NotificationPriority.Queue,
        type: NotificationType.Polite,
      }], [
        notification_auth_error,
        notification_book_loading,
        notification_book_loaded,
        notification_books_loading
      ]);
    } else if (apiStatus === RequestStatus.SUCCESS && user !== null) {
      log('NotificationsProvider ------- ', "currentMess" , '------------------------------------------ adding messsage' );
      log(notification_auth_success.id);
      addMessages([{
        ...notification_auth_success,
        message: t(notification_auth_success.id),
        priority: NotificationPriority.Queue,
        type: NotificationType.Polite,
      }], [
        notification_auth_loading,
        notification_auth_error,
        notification_auth_disconnected
      ]);
    } else if (apiStatus === RequestStatus.ERROR) {
      log(notification_auth_error);
      addMessages([{
        ...notification_auth_error,
        message: t(notification_auth_error.id),
        priority: NotificationPriority.Override,
        type: NotificationType.Assertive,
      }], [notification_auth_loading, notification_auth_success]);
    }
  }, [addMessages, user, isPending, apiStatus]);

  useAddQuickLink(addInstructions);

  const handleDisconnect = useCallback(() => {
    setPrevent(true);
    disconnect();
    log(notification_auth_disconnected.id);
    log("DISCONNECT CALLED");
    addMessages([{
      ...notification_auth_disconnected,
      message: t(notification_auth_disconnected.id),
      priority: NotificationPriority.Override,
      type: NotificationType.Assertive,
    }], [
      notification_auth_loading,
      notification_auth_error,
      notification_auth_success
    ]);
  }, [setPrevent, disconnect, addMessages]);

  return (
    <>
      <h2 id={loginWidgetTitleId} className="visually-hidden">{t("Login Widget")}</h2>
      {
        (((isPending || isTransactionInProgress) || !user || isAccountRoute) && (
          <div
            className={`Login__Container Login__Container--${!user ? "disconnected" : "connected"}`}
            tabIndex={TabIndex.reachablePriorityLowest}
          >
            {((isPending || isTransactionInProgress) && (
              <Loading />
            )) || ((!user && (
              <form onSubmit={e => e.preventDefault()} className="Login__Form">
                <div className="Login__InputContainer">
                  <TextInput
                    id={loginInputId}
                    label={t("BBR Number")}
                    type="username"
                    value={username}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    onFocus={() => !isAnyInputFocused && setPrevent(true)}
                    onBlur={() => isAnyInputFocused && setPrevent(false)}
                    autoComplete="username"
                    aria-required="true"
                    fullWidth
                  />
                </div>
                <div className="Login__InputContainer">
                  <TextInput
                    id={passwordInputId}
                    label={t("Password")}
                    type="password"
                    value={password}
                    onChange={(e) => { setPassword(e.target.value); }}
                    onFocus={() => !isAnyInputFocused && setPrevent(true)}
                    onBlur={() => isAnyInputFocused && setPrevent(false)}
                    onKeyDown={(e) => {
                      if (e.key !== "Enter") return;
                      triggerSubmit();
                    }}
                    aria-required="true"
                    autoComplete="current-password"
                    fullWidth
                  />
                </div>
                <Button
                  onClick={(!isPending && triggerSubmit) || undefined}
                  disabled={isPending}
                  aria-busy={isPending}
                  fullWidth
                >
                  {t("Login")}
                </Button>
              </form>
            )) || ((isAccountRoute && (
                <>
                  <p
                    className={`AccountLink AccountLink--disabled`}
                  >
                    {t("Welcome")}
                    {" "}
                    {user?.displayName}
                  </p>
                  <button className="Login__DisconnectButton" onClick={handleDisconnect}
                  >
                    {t("Disconnect")}
                  </button>
                </>
              )) || null)
          )}
          </div>
        )) || (
          <button
            className={`AccountLink AccountLink--enabled clickable`}
            onClick={() => navigate("/account")}
            aria-label={t("Go to user account page")}
          >
            <MdAccountCircle className={`AccountLink__Badge AccountLink__Badge--enabled`} aria-hidden={true} />
            <span className={`AccountLink__Username`}>{user?.displayName}</span>
          </button>
        )
      }
    </>
  );
};

export default Login;

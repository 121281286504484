import React from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import Copyright from "../MeowFooter/Copyright";
import FAQLinks from "../FAQLinks"; // Assuming this component exists
import FooterInfo from "./Info";
import { LinkableElementId } from "../../contexts/QuickLinks/QuickLinksContext";

type ComponentProps = {
  modifier?: "vertical-line";
};

export default function Footer({ modifier }: ComponentProps): JSX.Element {
  const { t } = useTranslation(["common"]);
  return (
    <div className={`Footer${modifier ? ` Footer--${modifier}` : ""}`}>
      <div className="Footer__Columns">
        <div className="Footer__SideFiller" role="presentation" aria-hidden={true}></div>
        <div className="Footer__ContentContainer">
          <div className="Footer__Content">
            <section className="Footer__Column" id={LinkableElementId.pageFooterContact}>
              <h3>{t('Contact')}</h3>
              <FooterInfo />
            </section>
            <section className="Footer__Column" id={LinkableElementId.pageFooterFAQ}>
              <h3>{t('Help Quick Links')}</h3>
              <FAQLinks variant="onDark" />
            </section>
          </div>
          <div className="Footer__Credits">
            <Copyright />
          </div>
        </div>
      </div>
    </div>
  );
}
import React from "react";
import NavBar from "../../components/NavBar";
import BookResults from "../../components/BookResults";
import Footer from "../../components/Footer";
import Page from "../../components/Page";
import BooksProvider from "../../contexts/Books/BooksProvider";

export default function HomePage(): JSX.Element {
  return (
    <BooksProvider>
      <Page
        header={<NavBar modifier="left-on-mobile" />}
        main={<BookResults />}
        footer={<Footer />}
      />
    </BooksProvider>
  );
}

import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useMatch, Navigate, useNavigate, Route, Routes } from "react-router-dom";
import DownloadItemList from "../../components/DownloadItemList";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useTranslation } from "react-i18next";
import UserInfo from "../../components/UserInfo";
import "./index.scss";
import WishItemList from "../../components/WishItemList";
import { UserContext } from "../../contexts/User/UserContext";
import { ucfirst } from "../../utils/string";
import { Tab } from "../../components/Tabs/Tab";
import { TabList } from "../../components/Tabs/TabList";
import { TabPanel } from "../../components/Tabs/TabPanel";
import { LinkableElementId, LinkPriority, LinksDisplayRegion } from "../../contexts/QuickLinks/QuickLinksContext";
import { useReplaceQuickLink } from "../../hooks/useReplaceQuickLink";
import useFocusOn from "../../hooks/useFocusOn";
import { PreferenceKey } from "../../types/preferences";
import SectionSelect from "../../components/SectionSelect";
import { useStoragePreference } from "../../contexts/Preferences/PreferencesProvider";
import SplitPage from "../../components/Page/SplitPage";

enum TabName {
  wishes = "wishes",
  downloads = "downloads",
}

const mainContentId = 'main-content';
const asideContainerId = 'aside-user-info';

const containerLink = {
  id: LinkableElementId.userAccountPageContent,
  href: `#${mainContentId}`,
  label: 'Go to content',
  priority: LinkPriority.mainContent,
  actionPriority: 1,
};

const replaceInstruction = {
  oldLinkId: LinkableElementId.pageMain,
  newLink: containerLink,
  namespace: LinksDisplayRegion.pageComponentTop,
};

export default function UserAccountPage(): JSX.Element {
  const { user } = useContext(UserContext);
  const { t } = useTranslation(["common"]);
  const match = useMatch("/account/*");
  const navigate = useNavigate();
  const tabs = [TabName.wishes, TabName.downloads];
  const [activeIndex, setActiveIndex] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const [focusAllowed, setFocusAllowed] = useState<boolean>(false);

  useEffect(() => {
    const tabName = match?.params["*"] as TabName;
    const index = tabs.indexOf(tabName || TabName.wishes);
    setActiveIndex(index >= 0 ? index : 0);
  }, [match]);

  useDocumentTitle(`${user?.displayName || t("Guest user")}`);

  const handleTabChange = useCallback((index: number) => {
    navigate(`/account/${tabs[index]}`);
    setActiveIndex(index);
  }, [navigate, tabs]);

  useEffect(() => {
    setFocusAllowed(true);
  }, []);

  useReplaceQuickLink(replaceInstruction);
  useFocusOn(containerRef, focusAllowed);
  const { preference: isReplaceDropdownWithTabs } = useStoragePreference<boolean>(PreferenceKey.USE_TABS_NOT_DROPDOWN);

  return (
    <SplitPage
      aside={<UserInfo id={asideContainerId} />}
      main={
        <div className="UserAccount__Content" ref={containerRef} id={mainContentId}>
          {isReplaceDropdownWithTabs ? (
            <TabList
              activeIndex={activeIndex}
              onTabChange={handleTabChange}
              label={t("User Account Sections Tabs")}
            >
              {tabs.map((tabName) => (
                <Tab
                  key={tabName}
                  id={`tab-${tabName}`}
                  controls={`tabpanel-${tabName}`}
                >
                  {t(ucfirst(tabName))}
                </Tab>
              ))}
            </TabList>
          ) : (
            <SectionSelect
              options={tabs}
              value={activeIndex}
              onChange={handleTabChange}
            />
          )}

          <Routes>
            <Route path="/" element={<Navigate to={TabName.wishes} />} />
            <Route
              path={TabName.wishes}
              element={
                <TabPanel
                  id={`tabpanel-${TabName.wishes}`}
                  labelledBy={`tab-${TabName.wishes}`}
                  isActive={activeIndex === tabs.indexOf(TabName.wishes)}
                  hasFocusableChildren={true}
                >
                  <WishItemList />
                </TabPanel>
              }
            />
            <Route
              path={TabName.downloads}
              element={
                <TabPanel
                  id={`tabpanel-${TabName.downloads}`}
                  labelledBy={`tab-${TabName.downloads}`}
                  isActive={activeIndex === tabs.indexOf(TabName.downloads)}
                  hasFocusableChildren={true}
                >
                  <DownloadItemList />
                </TabPanel>
              }
            />
          </Routes>
        </div>
      }
    />
  );
}
import React from "react";
import Page from "..";
import AnimatedVerticalLine from "../../AnimatedVerticalLine";
import Footer from "../../Footer";
import NavBar from "../../NavBar";
import './index.scss';

type ComponentProps = { aside?: React.ReactNode; main: React.ReactNode; };

const SplitPage: React.FC<ComponentProps> = ({ aside = null, main }) => {
  return (
    <Page
      header={<NavBar modifier="centered-logo" />}
      main={
        <div className="SplitPage__SplitContainer">
          <AnimatedVerticalLine />
          <aside className="SplitPage__SideContent">{aside}</aside>
          <div className="SplitPage__MainContent">{main}</div>
        </div>
      }
      footer={<Footer modifier='vertical-line' />}
    />
  );
};

export default SplitPage;
import React, { useMemo } from "react";
import Loading from "../Loading";
import Selector from "./Selector";
import useGenres from "../../hooks/useGenres";
import { useTranslation } from "react-i18next";
import { Format, NewSearchProps, QueryType, YouthText } from "../../types/search";
import "./index.scss";
import CheckboxGroup from "../CheckboxGroup";
import Switch from "../Switch";
import SearchButton from "../SearchButton";
import useErrorManager from "../../hooks/useErrorManager";

type ComponentProps = {
  setSearchQuery: React.Dispatch<React.SetStateAction<NewSearchProps>>;
  searchQuery: NewSearchProps;
  triggerSearch: () => void;
  alreadySearched: boolean;
}

const ns = 'AdvancedSearch';

const AdvancedSearch: React.FC<ComponentProps> = ({
  setSearchQuery,
  searchQuery,
  alreadySearched,
  triggerSearch
}) => {
  const { t } = useTranslation(["common"]);
  const { genres, isPending, attemptRecoveryFromError } = useGenres(ns);

  useErrorManager(ns, attemptRecoveryFromError);

  const genresWithFirstOption = useMemo(() => [{ code: "", text: t("Genre") } , ...genres], [genres]);
  const searchTypes = useMemo(() => [
    { value: "text", label: t("SearchBy") },
    { value: "title", label: t("Title") },
    { value: "author", label: t("Author") },
    { value: "reader", label: t("Reader") },
  ], [t]);

  const localizedFormatOptions = [
    {
      value: Format.daisy, label: t("Audiobook"),
      selected: (
        searchQuery.format === Format.daisy
        || searchQuery.format === Format.all
      )
    },
    {
      value: Format.brf, label: t("Braille"),
      selected: (
        searchQuery.format === Format.brf
        || searchQuery.format === Format.all
      )
    },
  ];

  return (
    <div
      className="AdvancedSearch__Container"
      aria-label={t("Advanced Search Options")}
    >
      <div className="AdvancedSearch__Row">
        {(isPending && (
          <Loading />
        )) || (
          <Selector
            options={genresWithFirstOption}
            onChange={(e) => {
              setSearchQuery((prevState) => ({
                ...prevState,
                gatewayGenreCode: e.target.value,
              }));
            }}
            value={searchQuery.gatewayGenreCode}
            extractor={({ code, text }) => [code, text]}
          />
        )}
      </div>
      <div className="AdvancedSearch__Row">
        <Selector
          value={searchQuery.queryType}
          options={searchTypes}
          extractor={({ value, label }) => [value, label]}
          onChange={(e) => {
            setSearchQuery((prevState) => ({
              ...prevState,
              queryType: e.target.value as QueryType,
            }));
          }}
        />
      </div>
      <div className="AdvancedSearch__Row">
        <Switch
          id={"YouthSwitch"}
          label={t("Youth")}
          onChange={toggled => setSearchQuery(prevState => ({
            ...prevState,
            youth: toggled ? YouthText.yes : YouthText.no,
          }))}
          toggled={Boolean(searchQuery.youth && searchQuery.youth === YouthText.yes)}
        />
      </div>
      <div className="AdvancedSearch__Row">
        <CheckboxGroup
          title={t("Formats")}
          options={localizedFormatOptions}
          onChange={selectedOptions => {
            const format = (selectedOptions.length === localizedFormatOptions.length || selectedOptions.length <= 0)
              ? Format.all
              : selectedOptions[0] as Format;

            setSearchQuery((prevState) => ({
              ...prevState,
              format: selectedOptions.length <= 0
                ? (
                  prevState.format === Format.daisy
                    ? Format.brf
                    : (
                      prevState.format === Format.brf
                        ? Format.daisy
                        : format
                    )
                )
                : format
            }));
          }}
        />
      </div>
      <div className="AdvancedSearch__Row">
        <SearchButton triggerSearch={triggerSearch} disabled={alreadySearched} />
      </div>
    </div>
  );
}

export default AdvancedSearch;
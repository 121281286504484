import React from 'react';
import './index.scss';
import { ucfirst } from '../../utils/string';
import { useTranslation } from 'react-i18next';
import Selector from '../AdvancedSearch/Selector';

interface SectionSelectProps {
  options: string[];
  value: number;
  onChange: (index: number) => void;
}

const sectionSelectorId = "section-select";

const SectionSelect: React.FC<SectionSelectProps> = ({
  options,
  value,
  onChange,
}) => {
  const { t } = useTranslation(['common']);

  return (
    <div className="SectionSelect">
      <label className="SectionSelect__Label" htmlFor={sectionSelectorId}>
        {t("Select a section")}
      </label>
      <Selector
        id={sectionSelectorId}
        options={options}
        onChange={(e) => onChange(Number(e.target.value))}
        value={String(value)}
        extractor={(tabname, index) => [String(index), t(ucfirst(tabname))]}
      />
    </div>
  );
};

export default SectionSelect;

import React from 'react';
import './index.scss';
import { TabIndex } from '../../types/accessibility';


const FAQ: React.FC = () => {
  return (
    <div className="faq-content">
      <section id="inscription">
        <h3 tabIndex={TabIndex.notReachableButProgramaticallyFocusable}>Comment s'inscrire à la BBR ?</h3>
        <p>
          L'inscription est gratuite et donne accès à l'ensemble de la collection de livres audio et braille de la BBR. Pour ce faire, il suffit de remplir{' '}
          <a href="https://abage.ch/association/bibliotheque-braille-romande-et-livre-parle-bbr/inscription/" target="_blank" rel="noopener noreferrer">
            un formulaire d'inscription
          </a>{' '}
          et de nous transmettre une attestation qui justifie la difficulté ou l'incapacité de lecture.{' '}
          <a href="https://abage.ch/association/bibliotheque-braille-romande-et-livre-parle-bbr/contact/" target="_blank" rel="noopener noreferrer">
            Nous contacter
          </a>{' '}
          pour des informations complémentaires.
        </p>
      </section>

      <section id="connexion">
        <h3 tabIndex={TabIndex.notReachableButProgramaticallyFocusable}>Comment se connecter au catalogue ?</h3>
        <p>Pour vous identifier à notre catalogue de téléchargement de livres, il est nécessaire de saisir un numéro d'utilisateur et un mot de passe et de valider avec le bouton "se connecter".</p>
        <p>Lors de votre inscription et sur demande pour l'utilisation du catalogue en ligne, nous vous transmettons un numéro d'utilisateur et un mot de passe.</p>
        <p>Ce numéro et ce mot de passe peuvent également servir pour nos{' '}
          <a href="https://abage.ch/association/bibliotheque-braille-romande-et-livre-parle-bbr/prestations/applications-de-telechargement/" target="_blank" rel="noopener noreferrer">
            applications mobiles
          </a>{' '}
          (iOS et Android) de téléchargement de livres audio, BBR Player et BiblioPlayer.
        </p>
        <p>En cas de perte de vos identifiants, merci de{' '}
          <a href="https://abage.ch/association/bibliotheque-braille-romande-et-livre-parle-bbr/contact/" target="_blank" rel="noopener noreferrer">
            nous contacter
          </a>.
        </p>
      </section>

      <section id="telechargement">
        <h3 tabIndex={TabIndex.notReachableButProgramaticallyFocusable}>Comment télécharger un livre ?</h3>
        <p>La recherche, l'écoute des extraits et la consultation du catalogue peuvent s'effectuer sans identifiants. Cependant, l'accès au téléchargement de livres audio et braille numériques nécessite d'être inscrit à la BBR et d'avoir saisi son identifiant et son mot de passe sur la page d'accueil du catalogue.</p>
        <p>Tous les titres affichés dans le catalogue peuvent être téléchargés à partir d'un lien de téléchargement disponible pour chaque ouvrage. Valider le lien lancera automatiquement le téléchargement : les livres audio sont des fichiers d'une taille importante qui peuvent nécessiter une durée de téléchargement de quelques minutes. Les livres en braille numériques seront plus rapides à télécharger.</p>
      </section>

      <section id="ecoute-daisy">
        <h3 tabIndex={TabIndex.notReachableButProgramaticallyFocusable}>Comment écouter un livre audio au standard DAISY ?</h3>
        <p>DAISY est l’acronyme de Digital Accessible Information SYstem, soit système d’information numérique accessible. C’est un standard qui permet d’organiser et de structurer l’information audio, afin que la lecture puisse se faire de la même manière que pour un livre imprimé. Ce standard offre des possibilités considérables aux publics empêchés de lire des supports imprimés : il permet de se déplacer à l'intérieur (par section, par chapitre, etc.), de sélectionner des passages à réécouter, de reprendre la lecture à partir de l'endroit où elle a été arrêtée, de contrôler la vitesse de lecture, etc. Tous les livres de la collection audio de la BBR sont au standard DAISY.</p>
        <h3>Avec un ordinateur</h3>
        <p>Une fois téléchargé à partir de notre catalogue, un livre audio peut être lu avec divers logiciels de lecture.</p>
        <p>
          Le{' '}
          <a href="https://thorium.edrlab.org/fr/" target="_blank" rel="noopener noreferrer">
            logiciel Thorium
          </a>{' '}
          est disponible en français et est gratuit. : version pour Windows, Mac ou Linux
        </p>
        <p>
          Le{' '}
          <a href="https://yourdolphin.com/EasyReader-App" target="_blank" rel="noopener noreferrer">
            Logiciel Dolphin EasyReader
          </a>{' '}
          est gratuit et disponible en français pour Windows.
        </p>
        <h3>Avec un smartphone ou une tablette (iOS ou Android)</h3>
        <p>Pour télécharger et écouter des livres audio sur un téléphone ou une tablette, nous vous conseillons d'installer et d'utiliser les{' '}
          <a href="https://abage.ch/association/bibliotheque-braille-romande-et-livre-parle-bbr/prestations/applications-de-telechargement/" target="_blank" rel="noopener noreferrer">
            applications mobiles de la BBR
          </a>.
        </p>
        <p>Néanmoins, les livres téléchargés à partir du catalogue de la BBR peuvent être lus avec :</p>
        <p>
            L'application Dolphin EasyReader qui est gratuite.{' '}
            <a href="https://play.google.com/store/apps/details?id=com.yourdolphin.easyreader&hl=fr" target="_blank" rel="noopener noreferrer">
              Version Android
            </a>{' '}
            et{' '}
            <a href="https://apps.apple.com/fr/app/dolphin-easyreader/id1161662515" target="_blank" rel="noopener noreferrer">
              Version iOS
            </a>
        </p>
      </section>

      <section id="lecture-braille">
        <h3 tabIndex={TabIndex.notReachableButProgramaticallyFocusable}>Comment lire un ouvrage en braille numérique ?</h3>
        <p>Un livre en braille numérique est disponible sous la forme d'un fichier. Ce fichier est au format .BRF. Il peut être lu à l'aide d'une plage braille ou d'un bloc-notes braille.</p>
        <p>Les livres en braille numériques téléchargeables depuis notre catalogue sont constitués d'un dossier contenant 2 fichiers .BRF distincts :</p>
        <ul>
          <li>local.brf (table française)</li>
          <li>us.brf (table américaine)</li>
        </ul>
        <p>Pour les lire, il est ainsi nécessaire d'avoir un périphérique compatible. Quelques exemples de modèles :</p>
        <ul>
          <li>Braille NoteTouch de HumanWare, plusieurs modèles existent.</li>
          <li>BrailleSense de HIMS, plusieurs modèles également</li>
        </ul>
        <p>Vous pouvez télécharger les livres en BRF de notre catalogue à partir d'un ordinateur puis les charger sur votre périphérique (bloc-notes braille par exemple) à l'aide d'une carte mémoire, de type carte SD ou les copier sur une clé USB.</p>
      </section>

      <section id="peripherique-braille">
        <h3 tabIndex={TabIndex.notReachableButProgramaticallyFocusable}>Où se procurer un périphérique spécifique pour la lecture du braille ?</h3>
        <p>En Suisse, nous vous conseillons de vous renseigner auprès de :</p>
        <ul>
          <li>
            <a href="https://accesstech.ch/fr/" target="_blank" rel="noopener noreferrer">
              Accesstech
            </a>
          </li>
          <li>
            <a href="https://www.srihv.ch/" target="_blank" rel="noopener noreferrer">
              Service Romand d'informatique pour handicapés de la vue (SRIHV)
            </a>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default FAQ;
import React from 'react';
import { NamespacedLinksDictionary } from '../../contexts/QuickLinks/QuickLinksContext';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuickLinks } from '../../contexts/QuickLinks/QuickLinksProvider';

type ComponentProps = { namespace: keyof NamespacedLinksDictionary };

const QuickLinks: React.FC<ComponentProps> = ({ namespace }) => {
  const { t } = useTranslation(['common']);
  const { links } = useQuickLinks();
  return (
    links[namespace] && (
      <>
        {links[namespace].map((link) => (
          link.href.slice(0, 1) === "#"
            ?  (
              <a key={link.id} href={link.href} className="visually-hidden">
                {t(link.label)}
              </a>
            )
            : (
              <Link key={link.id} to={link.href} className="visually-hidden">
                {t(link.label)}
              </Link>
            )
        ))}
      </>
    ) || null);
}

export default QuickLinks;
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import FAQ from '../../components/FAQ';
import FAQLinks from '../../components/FAQLinks';
import { useTranslation } from 'react-i18next';
import SplitPage from '../../components/Page/SplitPage';
import { TabIndex } from '../../types/accessibility';
import './index.scss';
import { LinkableElementId, LinkPriority, LinksDisplayRegion } from '../../contexts/QuickLinks/QuickLinksContext';
import { useReplaceQuickLink } from '../../hooks/useReplaceQuickLink';
import { useQuickLinks } from '../../contexts/QuickLinks/QuickLinksProvider';

const containerId = 'faq-container'
const mainContentId = containerId;

const containerLink = {
  id: LinkableElementId.userAccountPageContent,
  href: `#${mainContentId}`,
  label: 'Go to content',
  priority: LinkPriority.mainContent,
  actionPriority: 1,
};

const replaceInstruction = {
  oldLinkId: LinkableElementId.pageMain,
  newLink: containerLink,
  namespace: LinksDisplayRegion.pageComponentTop,
};

const FAQPage: React.FC = () => {
  const { t } = useTranslation(["common"]);
  const location = useLocation();

  useEffect(() => {
    const id = setTimeout(() => {
      const element = document.querySelector(location.hash || `#${containerId}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        const heading = element.querySelector('h2');
        const finalHeading = heading || element.querySelector('h3');
        if (finalHeading) {
          finalHeading.focus();
        }
      }
    }, 300);
    return () => clearTimeout(id);
  }, []);

  useReplaceQuickLink(replaceInstruction);

  const { links, removeLink } = useQuickLinks();

  useEffect(() => {
    if (-1 === links.pageComponentTop.findIndex(l => l.id === LinkableElementId.pageFooterFAQ)) return;
    removeLink({
      id: LinkableElementId.pageFooterFAQ,
      namespace: LinksDisplayRegion.pageComponentTop
    })
  }, [removeLink, links]);

  return (
    <SplitPage
      main={
        <div
          className='FAQ__Content'
          id={containerId}
        >
          <h2 className="Page__Title Page__Title--left" tabIndex={TabIndex.notReachableButProgramaticallyFocusable}>{t('Help')}</h2>
          <FAQLinks variant="onWhite" />
          <FAQ />
        </div>
      }
    />
  );
};

export default FAQPage;
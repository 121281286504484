import { useEffect } from 'react';
import './index.scss';

const AnimatedVerticalLine = () => {
  useEffect(() => {
    const rootElement = document.getElementById('root');
    const pageContainer = document.querySelector('.Page__Container');
    if (!rootElement || !pageContainer) return;

    // Create the line element
    const line = document.createElement('div');
    line.ariaHidden = 'true';
    line.className = 'VerticalLine';
    rootElement.appendChild(line);

    const updateLinePosition = () => {
      // Calculate left offset based on .Page__Container
      const pageRect = pageContainer.getBoundingClientRect();
      const leftOffset = pageRect.left;

      // Calculate top and height based on #root
      const rootRect = rootElement.getBoundingClientRect();
      const rootTop = rootRect.top + window.scrollY; // Absolute top of #root
      const documentHeight = document.documentElement.scrollHeight;
      const lineHeight = documentHeight - rootTop; // Height to document bottom

      // Set CSS variables
      document.documentElement.style.setProperty('--vertical-line-left-offset', `${leftOffset}px`);
      document.documentElement.style.setProperty('--vertical-line-top', `0px`); // Since it's absolute to #root
      document.documentElement.style.setProperty('--vertical-line-height', `${lineHeight}px`);
    };

    setTimeout(updateLinePosition, 800); ;

    window.addEventListener('resize', updateLinePosition);

    return () => {
      if (rootElement.contains(line)) rootElement.removeChild(line);
      window.removeEventListener('resize', updateLinePosition);
    };
  }, []);

  return null;
};

export default AnimatedVerticalLine;
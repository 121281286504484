import React, { useContext, useState } from 'react';
import { PREFERENCES } from '../../config/preferences';
import { PreferencesContext } from './PreferencesContext';
import { PreferenceKeyType } from '../../types/preferences';

export const PreferencesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [preferences, setPreferences] = useState(() => (
    Object.entries(PREFERENCES).reduce((prefs, [key, config]) => {
      const stored = localStorage.getItem(key);
      return {
        ...prefs,
        [key]: (stored ? JSON.parse(stored) : config.defaultValue)
      };
    }, PREFERENCES)
  ));

  const updatePreference = <T,>(key: PreferenceKeyType, value: T) => {
    localStorage.setItem(key, JSON.stringify(value));
    setPreferences(prev => ({ ...prev, [key]: value }));
  };

  return (
    <PreferencesContext.Provider value={{ preferences, updatePreference }}>
      {children}
    </PreferencesContext.Provider>
  );
};

export const usePreferences = () => {
  const context = useContext(PreferencesContext);
  if (context === undefined) {
    throw new Error('usePreferences must be used within a PreferencesProvider');
  }
  return context;
};

// Typed hook for specific preference
export const useStoragePreference = <T,>(key: PreferenceKeyType) => {
  const { preferences, updatePreference } = usePreferences();
  return {
    preference: preferences[key] as T,
    updatePreference: (value: T) => updatePreference(key, value)
  };
};

import React, { useState, useCallback, useMemo } from "react";
import { AddRemove, ErrorContext, ErrorToDismissAction, SourcedErrors, TimedError } from "./ErrorContext";
import { l } from "../../utils/log";
import { unmerge } from "swiss-army-knifey";

interface GenresProviderProps {
  children: React.ReactNode;
}

const removeAt = <T extends any>(list: T[], idx: number) => (
  (list.length > 0 && [...list.slice(0, idx), ...list.slice(idx + 1)]) || []
);

export const getSplitErrorsBySource = (sourcedErrors: SourcedErrors, errorSource: string) => {
  const splition = unmerge(
    sourcedErrors,
    ({ source }) => source.includes(errorSource)
  );

  return splition;
}

const ns = 'ErrorProvider';
const log = l(ns);

const truncate = (n: number, zeros: number) => Math.floor(n / Math.pow(10, zeros));
const secondsTimestamp = (date?: Date) => (date && truncate(date.getTime(), 3))

export function sameNotUndefinedTimedErrors(
  a: TimedError | undefined,
  b: TimedError | undefined
): a is TimedError {
  if (!a || !b || typeof a !== typeof b) {
    return false;
  };
  const timestampsMatch = secondsTimestamp(a?.date) === secondsTimestamp(b?.date);
  return timestampsMatch
    && a?.error.message === b?.error.message;
}

export function getErrorIndexFromSrouce(state: SourcedErrors, source: string, timedError: TimedError | null) {
  return state.findIndex(
    ({ source: stateSource, timedError: stateTimedError}) => stateSource.includes(source) && (!timedError || sameNotUndefinedTimedErrors(stateTimedError, timedError))
  )
}

const addRemoveSourcedTimedErrorGen = (
  setSourcedErrors: React.Dispatch<React.SetStateAction<SourcedErrors>>
) => (
  source: string,
  timedError: TimedError | null,
  addRemove: AddRemove
) => {
  setSourcedErrors((prevState) => {
    switch (addRemove) {
      case AddRemove.add:
        if (timedError === null) {
          throw new Error("Bug cannot add null timed error");
        }
        const errorIndexInSource = getErrorIndexFromSrouce(prevState, source, timedError);
        if (errorIndexInSource !== -1) {
          return prevState;
        };
        const nextStateAdd = [
          ...prevState,
          { source, timedError } // LILO
        ];
        return nextStateAdd;
      case AddRemove.remove:
      default: // AddRemove.remove
        if (!prevState || prevState.length <= 0) {
          return prevState;
        };
        const errorIndexInSourceRemove = getErrorIndexFromSrouce(prevState, source, timedError);
        if (errorIndexInSourceRemove === -1) {
          return prevState;
        };
        const nextState = removeAt(prevState, errorIndexInSourceRemove);
        return nextState;
    }
  });
};

function ErrorProvider({ children }: GenresProviderProps) {
  const [sourcedErrors, setSourcedErrors] = useState<SourcedErrors>([]);
  const [shownErrorStack, setShownErrorStack] = useState<ErrorToDismissAction[]>([]);

  const addOrRemoveSourcedError = useCallback((
    source: string,
    timedError: TimedError | null,
    addRemove: AddRemove
  ) => {
    log(".................... addRemove Called", source, timedError, addRemove === AddRemove.add ? "add" : "remove");
    return addRemoveSourcedTimedErrorGen(setSourcedErrors)(
      source,
      timedError,
      addRemove
    );
  }, [setSourcedErrors]);

  const show = useCallback((err: ErrorToDismissAction) => {
    addOrRemoveSourcedError(err.source, err.timedError, AddRemove.remove);
    setShownErrorStack((prevState) => [err, ...prevState]);
  }, [setShownErrorStack, addOrRemoveSourcedError]);

  const dismissFirstFromSource = useCallback(
    (source: string) => {
      setShownErrorStack((prevState) => {
        const [selected, ] = getSplitErrorsBySource(prevState, source);
        if (selected.length <= 0) return prevState;
        const [first, ] = selected;
        const nextState = prevState.filter(
          ({ timedError: stateTimedError }) => !sameNotUndefinedTimedErrors(stateTimedError, first.timedError)
        );
        return nextState;
      });
    },
    [setShownErrorStack]
  );

  const errorContextValue = useMemo(() => {
    return {
      sourcedErrors,
      shownErrorStack,
      addOrRemoveSourcedError,
      removeFirstFromSource: (source: string) => addOrRemoveSourcedError(source, null, AddRemove.remove),
      show,
      dismissFirstFromSource,
    };
  }, [
    sourcedErrors,
    shownErrorStack,
    addOrRemoveSourcedError,
    show,
    dismissFirstFromSource
  ]);

  log('errorContextValue', errorContextValue);

  return (
    <ErrorContext.Provider value={errorContextValue}>
      {children}
    </ErrorContext.Provider>
  );
}

export default ErrorProvider;

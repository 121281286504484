import React, { DOMAttributes, ReactNode } from "react";
import "./index.scss";

type ButtonSize = 'sm' | 'md' | 'lg';
type ButtonVariant = 'default' | 'outline';

type ComponentProps = {
  onClick?: DOMAttributes<HTMLButtonElement>["onClick"];
  disabled?: boolean;
  className?: string;
  "aria-label"?: string;
  "aria-busy"?: boolean;
  icon?: ReactNode;
  children?: ReactNode;
  fullWidth?: boolean;
  size?: ButtonSize;
  variant?: ButtonVariant;
  noMargins?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<ComponentProps> = ({
  disabled,
  onClick,
  "aria-label": ariaLabel,
  "aria-busy": ariaBusy,
  className = "",
  icon,
  children,
  fullWidth = false,
  size = "md",
  variant = "default",
  noMargins = false,
  ...props
}) => {
  return (
    <button
      {...props}
      className={`Button${fullWidth ? ' Button--fullWidth' : ''}${noMargins ? ' Button--noMargins' : ''} Button--${size} Button--${variant} ${className}`}
      onClick={(!disabled && onClick) || undefined}
      aria-label={ariaLabel}
      aria-disabled={disabled}
      aria-busy={ariaBusy}
    >
      {icon && (
        <span className="Button__Icon">
          {icon}
        </span>
      )}
      {children}
    </button>
  );
};

export default Button;

import React, { useCallback, useContext, useEffect, useState } from "react";
import "./index.scss";
import { MdStar, MdStarOutline } from "react-icons/md";
import useResult from "../../hooks/useResult";
import { AddWishParams, addWish, deleteWish } from "../../api/implementations/booksApi";
import { RemoteServiceId } from "../../api/implementations/types";
import { UserContext } from "../../contexts/User/UserContext";
import { TabIndex } from "../../types/accessibility";
import { useTranslation } from "react-i18next";
import { AuthStyle } from "../../hooks/useResultBase";
import useErrorManager from "../../hooks/useErrorManager";

export type OnWishChangeProp = { nextIsWished: boolean; code: string; origin: RemoteServiceId; }

type RoundedLogoProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
  & {
  code: string;
  origin: RemoteServiceId;
  title: string;
  wished: boolean;
  size?: "card" | "full"
  onWishChange?: (prop: OnWishChangeProp) => void;
};

const ns = 'WishButton';

const paramsAreSame = (a: AddWishParams|null, b: AddWishParams|null) => {
  return (a === null && b === null)
    || (a !== null && b !== null && a.code === b.code && a.origin === b.origin);
}

export default function WishButton({
  size,
  wished,
  title,
  code,
  origin,
  onWishChange,
  ...rest
}: RoundedLogoProps) {
  const { t } = useTranslation(["common"]);
  const { user } = useContext(UserContext);
  const [isWished, setIsWished] = useState(wished);

  const finalNs = `${ns}-${code}-${origin}`;

  const {
    success: wishAdded,
    request: addRequest,
    isPending: isLoadingAddWish,
    attemptRecoveryFromError: attemptRecoveryFromErrorAdd,
  } = useResult(addWish, "success", paramsAreSame, AuthStyle.NO_AUTH_NULLIFY, null, finalNs);

  const {
    success: wishDeleted,
    request: deleteRequest,
    isPending: isLoadingDeleteWish,
    attemptRecoveryFromError: attemptRecoveryFromErrorDelete,
  } = useResult(deleteWish, "success", paramsAreSame, AuthStyle.NO_AUTH_NULLIFY, null, finalNs);

  useEffect(() => {
    if (isWished || wishAdded === null) return;
    setIsWished(wishAdded);
  }, [wishAdded]);

  useEffect(() => {
    if (!isWished || wishDeleted === null) return;
    setIsWished(!wishDeleted);
  }, [wishDeleted]);

  useEffect(() => {
    if (!wishAdded && !wishDeleted) return;
    onWishChange && onWishChange({ nextIsWished: isWished, code, origin });
  }, [isWished])

  const toggleWish = useCallback(() => {
    if (isLoadingAddWish || isLoadingDeleteWish) return;
    !isWished
      ? addRequest({ code, origin })
      : deleteRequest({ code, origin });
  }, [isLoadingAddWish, isLoadingDeleteWish, code, origin, isWished]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      toggleWish();
    }
  };

  useErrorManager(
    finalNs,
    isWished
      ? attemptRecoveryFromErrorDelete
      : attemptRecoveryFromErrorAdd
  );

  return user !== null ? (
    <div
      className={`WishButton WishButton--${size || "big"} ActionButton`}
      {...rest}
      onClick={toggleWish}
      onKeyDown={handleKeyDown}
      aria-label={`${isWished ? t("Remove from") : t("Add to")} ${t("your wishlist")}`}
      role="button"
      tabIndex={TabIndex.reachablePriorityLowest}
    >
      {isWished ? <MdStar aria-hidden="true" /> : <MdStarOutline aria-hidden="true" /> }
    </div>
  ) : null;
};

import { createContext } from "react";

export type QuickLink = {
  id: LinkableElementId;
  href: string;
  label: string;
  priority: number;
  actionPriority?: number;
};

export enum LinksDisplayRegion {
  common="common",
  pageComponentTop="pageComponentTop",
  pageComponentPreFooter="pageComponentPreFooter",
};

export enum LinkableElementId {
  navBar='nav-bar-container',
  searchBarInput='search-bar-input',
  pageHeader='page-header',
  pageMain='page-main',
  pageFooterContact='page-footer-contact',
  pageFooterFAQ='/faq',
  pageFooter='page-footer',
  booksContent='books-content',
  bookContent='book-content',
  userAccountPageContent='main-content',
  userAccountAsideContainer='user-aside',
  userAccountDetailsTitle='user-details-title',
  userAccountPreferencesTitle='user-preferences-title',
  accessibilityToolbar='accessibility-toolbar',
  loginWidget='login-widget',
}

export enum LinkPriority {
  mainContent=10,
  lowest=0,
  average=5,
  lower=1,
  low=2,
  high=7,
  higher=9,
  firstGuaranteed=100,
}

export enum UnfilledAction {
  removeLink="removeLink",
}

export const DEFAULT_PRIORITY = LinkPriority.lowest;

export type NamespacedLinksDictionary = { [key in LinksDisplayRegion]: QuickLink[]; };
export type UnfilledRequest = { id: LinkableElementId; actionPriority: number; namespace: LinksDisplayRegion; action: UnfilledAction; };
export type RemoveLinkProps = { id: LinkableElementId, actionPriority?: number; namespace?: LinksDisplayRegion; }
export type ReplaceLinkProps = { oldLinkId: LinkableElementId, newLink: QuickLink; namespace?: LinksDisplayRegion; }
export type NamespacedPrioritizedLink = { link: QuickLink, priority?: number; namespace?: LinksDisplayRegion; };
export type PreviousRelacements = {
  [key in LinkableElementId]: LinkableElementId[];
}

export type QuickLinksContextType = {
  addLink: (props: NamespacedPrioritizedLink) => void;
  addLinks: (links: NamespacedPrioritizedLink[]) => void;
  removeLink: (props: RemoveLinkProps) => void;
  replaceLink: (props: ReplaceLinkProps) => void;
  state: {
    namespacedLinksDictionary: NamespacedLinksDictionary;
    unfilledRequests: UnfilledRequest[];
    previousReplacements: PreviousRelacements;
  };
};

export const QuickLinksContext = createContext<QuickLinksContextType | undefined>(
  undefined
);
import React, { useContext } from 'react';
import './index.scss';
import { UserContext } from '../../contexts/User/UserContext';
import { getJwt, getJwtExpirationDate } from '../../utils/jwt';
import { useTranslation } from 'react-i18next';
import Switch from '../Switch';
import { PreferenceKey } from '../../types/preferences';
import { LinkableElementId, LinkPriority, LinksDisplayRegion } from '../../contexts/QuickLinks/QuickLinksContext';
import { useAddQuickLink } from '../../hooks/useAddQuickLink';
import { useStoragePreference } from '../../contexts/Preferences/PreferencesProvider';

interface User {
  addressId?: string;
  displayName?: string;
  firstName?: string;
  id?: string;
  login?: string;
  freeOne?: string;
  lastName?: string;
  mail?: string;
}

type ComponentProps = { id: string; };

const userPreferencesTitleId = "user-preferences-title";
const userDetailsTitleId = "user-details-title";

const userDetailsTitleLink = {
  id: LinkableElementId.userAccountDetailsTitle,
  href: `#${userDetailsTitleId}`,
  label: 'Go to user details',
  priority: LinkPriority.low,
  actionPriority: 1,
};

const addUserDetailsInstructions = {
  link: userDetailsTitleLink,
  namespace: LinksDisplayRegion.pageComponentTop,
};

const userPreferencesTitleLink = {
  id: LinkableElementId.userAccountPreferencesTitle,
  href: `#${userPreferencesTitleId}`,
  label: 'Go to user preferences',
  priority: LinkPriority.lower,
  actionPriority: 1,
};

const addUserPreferencesInstructions = {
  link: userPreferencesTitleLink,
  namespace: LinksDisplayRegion.pageComponentTop,
};

const UserInfo: React.FC<ComponentProps> = ({ id }) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation(["common"]);
  const { preference: isUseToolbar, updatePreference: updateIsUseToolbar } = useStoragePreference<boolean>(PreferenceKey.TOOLBAR_NAVIGATION);
  const { preference: isUseTopPaginationSortFilters, updatePreference: updateIsUseTopPaginationSortFilters } = useStoragePreference<boolean>(PreferenceKey.USE_TOP_PAGINATION_SORT_FILTERS);
  const { preference: isLimitToFiltersOnly, updatePreference: updateIsLimitToFiltersOnly } = useStoragePreference<boolean>(PreferenceKey.LIMIT_TO_FILTERS_ONLY);
  const { preference: isReplaceDropdownWithTabs, updatePreference: updateIsUseDropdownNotTabs } = useStoragePreference<boolean>(PreferenceKey.USE_TABS_NOT_DROPDOWN);

  useAddQuickLink(addUserDetailsInstructions);
  useAddQuickLink(addUserPreferencesInstructions);

  return (
    <aside className="UserInfo" id={id}>
      <h1 className="UserAccount__Title">{t("User Account")}</h1>
      <div className="UserInfo__ImageWrap">
        <img className="UserInfo__Image" src="/generic-user.jpg" alt={t("Generic user icon")} />
      </div>
      <div className="UserInfo__Info">
        <h2 className="UserInfo__DisplayName">{user?.displayName || t("Guest user")}</h2>

        {user && (
          <section className="UserInfo__Section">
            <h3 className="UserInfo__Section-Title" id={userDetailsTitleId}>{t("User Details")}</h3>
            <ul className="UserInfo__Details">
              <li className="UserInfo__Details--Item">{t("Email")}: {user.mail}</li>
              <li className="UserInfo__Details--Item">{t("Login")}: {user.login}</li>
              <li className="UserInfo__Details--Item">{t("Member since")}: {(user as User).freeOne}</li>
              <li className="UserInfo__Details--Item">{t("Session expires at")}: {getJwtExpirationDate(getJwt())?.toLocaleTimeString()}</li>
            </ul>
          </section>
        )}

        <section className="UserInfo__Section">
          <h3 className="UserInfo__Section-Title" id={userPreferencesTitleId}>{t("User Preferences")}</h3>
          <div className="UserInfo__Preferences">
            <Switch
              id="use-sort-filters"
              label={t("Results sorting options")}
              toggled={isUseTopPaginationSortFilters}
              onChange={updateIsUseTopPaginationSortFilters}
            />
            {isUseTopPaginationSortFilters && (
              <Switch
                id="use-filters"
                label={t("Display active filters only")}
                toggled={isLimitToFiltersOnly}
                onChange={updateIsLimitToFiltersOnly}
              />
            ) || null}
            {isUseTopPaginationSortFilters && (
              <Switch
                id="toolbar-navigation"
                label={t("Use toolbar navigation")}
                toggled={isUseToolbar}
                onChange={updateIsUseToolbar}
              />
            ) || null}
            <Switch
              id="use-tabs"
              label={t("Replace dropdown with tabs")}
              toggled={isReplaceDropdownWithTabs}
              onChange={updateIsUseDropdownNotTabs}
            />
          </div>
        </section>
      </div>
    </aside>
  );
};

export default UserInfo;

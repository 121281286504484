import { useEffect, useContext, useMemo, useCallback } from "react";
import { AddRemove, ErrorContext } from "../contexts/Error/ErrorContext";
import useApi from "../api/hooks/useApi";
import { anyOf } from "../utils/references";
import GenresContext from "../contexts/Genres/GenresContext";
import { getGenres } from "../api/implementations/genresApi";
import { Genre } from "../types/search";
import { getSplitErrorsBySource } from "../contexts/Error/ErrorProvider";

const ns = 'useGenres';

function useGenres(callerNs = ns) {
  const finalNs = `${ns}:${callerNs}`;
  const { genres, setGenres } = useContext(GenresContext);
  const {
    addOrRemoveSourcedError,
    removeFirstFromSource,
    sourcedErrors
  } = useContext(ErrorContext);

  const errorsFromHere = useMemo(
    () => {
      const [selected,] = getSplitErrorsBySource(sourcedErrors, finalNs)
      return selected;
    },
    [sourcedErrors, finalNs]
  );

  const setData = useCallback((data: Genre[]) => {
    setGenres(data);
  }, [setGenres]);

  const {
    exec: initGenres,
    error,
    isIdle,
    isError,
    isSuccess,
    isPending,
    clearApi,
    clearHook,
  } = useApi(getGenres, setData, ns);

  useEffect(() => {
    const exitEarly = anyOf({
      genresLength: genres.length > 0,
      NOT_isIdle: !isIdle,
      errorCreatedByMe: errorsFromHere.length,
    });

    if (exitEarly.bool) {
      return;
    }

    initGenres(undefined);
  }, [
    genres,
    initGenres,
    isIdle,
    errorsFromHere,
  ]);

  useEffect(() => {
    if (!isError) {
      removeFirstFromSource(ns);
    } else if (error && errorsFromHere.findIndex(({ timedError }) => timedError.error === error) === -1) {
      addOrRemoveSourcedError(ns, {
        error,
        date: new Date(),
      }, AddRemove.add);
    }
  }, [error, isError, addOrRemoveSourcedError, sourcedErrors]);

  useEffect(() => {
    if (!isSuccess) return; // handled by isError useEffect
    if (genres.length <= 0) {
      addOrRemoveSourcedError(ns, {
        error: new Error("Unable to fetch Genres, but request has succeeded."),
        date: new Date(),
      }, AddRemove.add);
    }
  }, [isSuccess, genres, addOrRemoveSourcedError]);

  useEffect(() => {
    if (genres.length > 0 && isSuccess) {
      clearHook();
    }
  }, [genres, isSuccess, clearHook]);

  return {
    genres,
    isPending,
    clearApi,
    attemptRecoveryFromError: () => {
      removeFirstFromSource(ns);
      clearApi();
    },
  };
}

export default useGenres;

import React, { useContext, useEffect, useState } from "react";
import DownloadItem from "../DownloadItem";
import Loading from "../Loading";
import "./index.scss";
import { DownloadDetailed, Pagination } from "../../api/implementations/types";
import { UserContext } from "../../contexts/User/UserContext";
import useResult from "../../hooks/useResult";
import { getDownloads } from "../../api/implementations/booksApi";
import { useTranslation } from "react-i18next";
import { AuthStyle } from "../../hooks/useResultBase";

const paginationsAreSame = (
  a: Pagination | null,
  b: Pagination | null
) => {
  return (
    (a === null && b === null) ||
    (b !== null &&
      a !== null &&
      a.start === b.start &&
      a.count === b.count)
  );
};

const showCount = 12;
const ns = 'DownloadItemList';

const DownloadItemList: React.FC = () => {
  const smartFetchCount = showCount + 1;
  const { t } = useTranslation(["common"]);
  const {
    params,
    downloads: items,
    invalidated,
    requestIfDifferentParams: setParams,
    isPending,
  } = useResult(getDownloads, "downloads", paginationsAreSame, AuthStyle.NO_AUTH_NULLIFY, {
    start: 0,
    count: smartFetchCount,
  }, ns);

  const [cumulatedItems, setCumulatedItems] = useState<DownloadDetailed[] | null>(null);
  const start = params?.start || 0;

  const [nextRequestWillReturnEmpty, setNextRequestWillReturnEmpty] = useState<boolean>(false);

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (items === null) {
      if (invalidated) {
        setCumulatedItems(null);
      }
      return;
    }
    if (items.length <= showCount) {
      setNextRequestWillReturnEmpty(true);
    }
    setCumulatedItems((prevState) => [
      ...(prevState !== null ? prevState : []),
      ...items.slice(0, showCount),
    ]);
  }, [items, invalidated]);

  return (
    <section className="DownloadItemList">
      <h2 className="DownloadItemList__Title">
        {t("Latest Downloads")}
      </h2>
      {cumulatedItems ? (
        <div>
          <ul className="DownloadItemList__List">
            {cumulatedItems.map((d) => (
              <li
                key={`${d.downloadID} ${d.noticeID} ${d.downloadDate}`}
              >
                <DownloadItem {...d} />
              </li>
            ))}
            {isPending && <Loading />}
          </ul>
          {!nextRequestWillReturnEmpty ? (
            <button
              className="DownloadItemList__More"
              onClick={() => {
                setParams({
                  start: start + showCount,
                  count: smartFetchCount, // fetch one more to know if more
                });
              }}
            >
              {t("Load more downloads")}
            </button>
          ) : (
            <p className="DownloadItemList__EOList">
              ... {t("End of your downloads list")}
            </p>
          )}
        </div>
      ) : user ? (
        <Loading />
      ) : (
        <p className="DownloadItemList__LoggedOutMessage">
          {t("Your downloads will show when you log in.")}
        </p>
      )}
    </section>
  );
};

export default DownloadItemList;

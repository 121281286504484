import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Login from "../Login";
import { getSearchRouteFromParams } from "../../utils/search";
import "./index.scss";
import SearchBar, { searchBarInputId } from "../SearchBar";
import { LinkableElementId, LinkPriority, LinksDisplayRegion } from "../../contexts/QuickLinks/QuickLinksContext";
import { useReplaceQuickLink } from "../../hooks/useReplaceQuickLink";
import { useTranslation } from "react-i18next";
import AppVersion from "../AppVersion";
import { useSearchQuery } from "../../contexts/SearchQuery/SearchQueryProvider";
import { useFocusNeeded } from "../../contexts/FocusNeeded/FocusNeededProvider";
import { l } from "../../utils/log";

const log = l("useFocusOn NavBar");

type ComponentProps = {
  modifier?: "centered-logo" | "left-on-mobile";
}

const containerLink = {
  id: LinkableElementId.searchBarInput,
  href: `#${searchBarInputId}`,
  label: 'Go to search bar',
  priority: LinkPriority.average,
  actionPriority: 1,
};

const replaceInstruction = {
  oldLinkId: LinkableElementId.pageHeader,
  newLink: containerLink,
  namespace: LinksDisplayRegion.pageComponentTop,
};

const NavBar: React.FC<ComponentProps> = function ({ modifier = "left-on-mobile"}) {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const { searchQuery, setSearchQuery, alreadySearched } = useSearchQuery();
  const { setNeedFocusOnContent } = useFocusNeeded();

  useReplaceQuickLink(replaceInstruction);

  return (
    <div
      className="Navbar__Container"
      id={LinkableElementId.navBar}
      aria-label={t("Sitewide utilities")}
    >
      <nav
        className={`Navbar__NavContainer Navbar__NavContainer--${modifier}`}
        aria-label={t("Main Navigation")}
      >
        <Link
          className="Navbar__LogoLink clickable"
          aria-label={t("Home")}
          onClick={event => {
            event.preventDefault();
            event.currentTarget.blur();
            const { youth, format } = searchQuery;
            setShowAdvancedSearch(false);
            navigate(getSearchRouteFromParams({
              page: 0,
              youth,
              format
            }));
            log("set not needed");
            setNeedFocusOnContent(false);
          }}
          to={"#"}
        >
          <img
            aria-hidden={true}
            className="Navbar__Logo"
            src={`/${import.meta.env.VITE_ORIGIN}-Logo.png`}
            alt={`Logo ${import.meta.env.VITE_ORIGIN_FULL}`}
          />
        </Link>
      </nav>
      <SearchBar
        id="search-bar"
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
        setShowAdvancedSearch={setShowAdvancedSearch}
        showAdvancedSearch={showAdvancedSearch}
        alreadySearched={alreadySearched}
      />
      <aside className="Navbar__AsideContainer" role="complementary">
        <Login />
        <AppVersion />
      </aside>
    </div>
  );
};

export default NavBar;

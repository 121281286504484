import { ExecOptions } from "../api/hooks/useApi";
import { AbortControllerRef } from "../api/api";
import useResultBase, { ABTest, AuthStyle } from "./useResultBase";

const transform = <F extends (...args: any[]) => any>(data: Awaited<ReturnType<F>>) => data;

function useFullResult<F extends (arg0: { params: any; abortRef?: AbortControllerRef }) => any>(
  fetchResult: F,
  paramsAreSame: ABTest<Parameters<F>[0]["params"]>,
  {
    authStyle,
    initialParams = null,
    callerNs = 'useFullResult',
    execOptions = undefined,
  }: {
    authStyle: AuthStyle;
    initialParams?: Parameters<F>[0]["params"] | null;
    callerNs?: string;
    execOptions?: ExecOptions;
  }
) {
  return useResultBase(fetchResult, transform<F>, paramsAreSame, {
    authStyle,
    initialParams,
    callerNs,
    execOptions,
  });
}

export default useFullResult;
import React from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';

const FooterInfo: React.FC = () => {
  const { t } = useTranslation(['common']);

  return (
    <>
      <p className="FooterInfo__BBR">
        <span className="FooterInfo__Description">{import.meta.env.VITE_ORIGIN_FULL}</span>
      </p>
      <p className="FooterInfo__Address">
        <span className="FooterInfo__Value">{import.meta.env.VITE_ENTITY_ADDRESS}</span>
      </p>
      <p>
        <span className="FooterInfo__Description">{t('Phone')}</span> : <a
          href={`tel:${import.meta.env.VITE_ENTITY_PHONE}`}
          className="FooterInfo__ValueLink"
          aria-label={`${t('Phone')}: ${import.meta.env.VITE_ENTITY_PHONE}`}
        >
          {import.meta.env.VITE_ENTITY_PHONE}
        </a>
      </p>
      <p>
        <span className="FooterInfo__Description"> {t('Email')}</span> : <a
          href={`mailto:${import.meta.env.VITE_ENTITY_EMAIL}`}
          className="FooterInfo__ValueLink"
          aria-label={`${t('Email')}: ${import.meta.env.VITE_ENTITY_EMAIL}`}
        >
          {import.meta.env.VITE_ENTITY_EMAIL}
        </a>
      </p>
      <p className="FooterInfo__ContentOwnership">
        <span className="FooterInfo__Value">{import.meta.env.VITE_ENTITY_NAME}.</span> <span className="FooterInfo__Value">{t('All rights reserved')}.</span>
      </p>
    </>
  );
};

export default FooterInfo;
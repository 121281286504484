import { createContext } from "react";
import { idLog } from "../../utils/references";
import { l } from "../../utils/log";
import { Book } from "../../types/book";

export type NullableBooks = Book[] | null;

export interface BooksContextType {
  books: NullableBooks;
  setBooks: React.Dispatch<React.SetStateAction<NullableBooks>>;
  totalCount: number;
  setTotalCount: React.Dispatch<React.SetStateAction<number>>;
  receivedCount: number;
  setReceivedCount: React.Dispatch<React.SetStateAction<number>>;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}

const log = l("BooksContext");

const BooksContext = createContext<BooksContextType>({
  books: null,
  setBooks: (prevState: NullableBooks | React.SetStateAction<NullableBooks>) => log(
    ...idLog(
      { id: "WARNING BooksContext:" },
      `not setting books ${prevState} wrap your consumer with a BooksProvider`
    )
  ),
  totalCount: 0,
  setTotalCount: (prevState: number | React.SetStateAction<number>) => log(
    ...idLog(
      { id: "WARNING BooksContext:" },
      `not setting totalCount ${prevState} wrap your consumer with a BooksProvider`
    )
  ),
  receivedCount: 0,
  setReceivedCount: (prevState: number | React.SetStateAction<number>) => log(
    ...idLog(
      { id: "WARNING BooksContext:" },
      `not setting receivedCount ${prevState} wrap your consumer with a BooksProvider`
    )
  ),
  searchTerm: "",
  setSearchTerm: (prevState: string | React.SetStateAction<string>) => log(
    ...idLog(
      { id: "WARNING BooksContext:" },
      `not setting searchTerm ${prevState} wrap your consumer with a BooksProvider`
    )
  ),
});

export default BooksContext;

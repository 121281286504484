import React, { useCallback, useContext, useMemo } from "react";
import { BiBraille, BiStop } from "react-icons/bi";
import { BookReducedInfo, MediaType } from "../../types/book";
import { AudioUrlContext } from "../../contexts/Audio/AudioUrlContext";
import { MdOutlineHeadset } from "react-icons/md";
import "./index.scss";
import { TabIndex } from "../../types/accessibility";
import { useTranslation } from "react-i18next";

function CornerFormatPlayer({ children, book, size, ...rest }: { children: React.ReactNode, book: BookReducedInfo, size: "card" | "full" | "miniature" } & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>): JSX.Element {
  const { t } = useTranslation(["common"]);
  const audioUrl: string | null = useMemo(() => (
    book?.files?.samples && book?.files?.samples[0]
      ? book.files.samples[0]
      : null
  ), [book?.files?.samples]);
  const { togglePlay, isPlaying } = useContext(AudioUrlContext);

  const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      togglePlay(audioUrl);
    }
  }, [togglePlay, audioUrl]);
  const handleClick = useCallback(() => togglePlay(audioUrl), [togglePlay, audioUrl]);

  const isBraille = book.mediaType === MediaType.BIN;
  const isAudioAvailable = audioUrl !== null && !isBraille;

  return (
    <div className={`CornerFormatPlayer__KeepRelativeToCover CornerFormatPlayer__KeepRelativeToCover--${size}`}>
      <div
        className={`HeadsetContainer${
          !isAudioAvailable
            ? (isBraille
              ? " HeadsetContainer--braille"
              : " HeadsetContainer--noAudio")
            : ""
        } ActionButton`}
        role={isBraille ? "img" : "button"}
        aria-disabled={isBraille ? undefined : !isAudioAvailable}
        onClick={(isAudioAvailable && handleClick) || undefined}
        onKeyDown={(isAudioAvailable && handleKeyDown) || undefined}
        tabIndex={isBraille ? undefined : TabIndex.reachablePriorityLowest}
        aria-label={`${isBraille
          ? `${t('Numeric braille format')}`
          : `${t('AudioBook')}. ${isAudioAvailable
            ? (!isPlaying(audioUrl) ? t("Play sample") : t("Pause sample"))
            : t("Sample unavailable")}`}`
        }
        {...rest}
      >
        {isPlaying(audioUrl) ? (
          <BiStop aria-hidden="true" tabIndex={TabIndex.notReachableButProgramaticallyFocusable} />
        ) : (
          book.mediaType === MediaType.BIN
            ? <BiBraille aria-hidden="true" tabIndex={TabIndex.notReachableButProgramaticallyFocusable} />
            : <MdOutlineHeadset aria-hidden="true" tabIndex={TabIndex.notReachableButProgramaticallyFocusable} />
        )}
      </div>
      {children}
    </div>
  );
}

export default CornerFormatPlayer;
import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import HomePage from "./pages/HomePage";
import ErrorPage from "./pages/ErrorPage";
import BookPage from "./pages/BookPage";
import UserAccountPage from "./pages/UserAccountPage";
import "./i18n";
import GenresProvider from "./contexts/Genres/GenresProvider";
import UserProvider from "./contexts/User/UserProvider";
import AudioUrlProvider from "./contexts/Audio/AudioUrlProvider";
import ErrorProvider from "./contexts/Error/ErrorProvider";
import "./theme/variables.scss";
import { useTranslation } from "react-i18next";
import { QuickLinksProvider } from "./contexts/QuickLinks/QuickLinksProvider";
import { NotificationProvider } from "./contexts/Notification/NotificationProvider";
import ApiProvider from "./api/contexts/ApiProvider";
import { PreferencesProvider } from "./contexts/Preferences/PreferencesProvider";
import { AccessibilityProvider } from "a11y-button";
import FAQPage from "./pages/FAQPage";

const router = createBrowserRouter([
  {
    path: '/faq',
    element: <FAQPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/:page?/:format?/:youth?/:sortCriteria?",
    element: (
      <HomePage />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/account/*",
    element: (
      <UserAccountPage />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/book/:bookCode",
    element: (
      <BookPage />
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/search/:by/:searchedText/:page/:genre/:format/:youth/:sortCriteria",
    element: (
      <HomePage />
    ),
    errorElement: <ErrorPage />,
  },
]);

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const htmlTag = document.documentElement;
    htmlTag.setAttribute("lang", i18n.language);

    const handleLanguageChange = (lng: string) => {
      htmlTag.setAttribute("lang", lng);
    };

    i18n.on("languageChanged", handleLanguageChange);

    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n]);

  return (
    <>
      <ErrorProvider>
        <ApiProvider>
          <NotificationProvider>
            <GenresProvider>
              <UserProvider>
                <AudioUrlProvider>
                  <QuickLinksProvider>
                    <PreferencesProvider>
                      <AccessibilityProvider defaultLanguage="fr">
                        <RouterProvider router={router} />
                      </AccessibilityProvider>
                    </PreferencesProvider>
                  </QuickLinksProvider>
                </AudioUrlProvider>
              </UserProvider>
            </GenresProvider>
          </NotificationProvider>
        </ApiProvider>
      </ErrorProvider>
    </>
  );
}

export default App;

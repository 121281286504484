import { useContext, useEffect, useMemo } from "react";
import { ErrorContext, ErrorToDismissAction, SourcedError } from "../contexts/Error/ErrorContext";
import { getSplitErrorsBySource, sameNotUndefinedTimedErrors } from "../contexts/Error/ErrorProvider";
import { ApiErrorResponse } from "../api/implementations/types";
import errorDsiplayHandleGen from "../utils/errorHandler";

export type ErrorDisplayHandleGen = (props: SourcedError) => ErrorToDismissAction;

const useErrorManager = (
  errorSource: string,
  attemptRecoveryFromError: (apiError?: ApiErrorResponse["error"]) => void,
  errorToDismissActionOverride?: (errorToDismissAction: ErrorToDismissAction) => ErrorToDismissAction
) => {
  const {
    sourcedErrors,
    show,
    addOrRemoveSourcedError,
    shownErrorStack,
  } = useContext(ErrorContext);
  // error from errorSource
  const mySourcedErrors = useMemo(() => {
    const [selected, ] = getSplitErrorsBySource(sourcedErrors, errorSource)
    return (selected || []).map(({ timedError }) => timedError);
  }, [sourcedErrors, errorSource]);
  const mySourcedError = mySourcedErrors ? mySourcedErrors[0] : undefined;
  // top of the shown errors stack (can be any from errorSource)
  // get top of stack error's primitive values to prevent useEffect rerenders
  // DUMMY when not for us or not existent
  // passLocalShownErrorToSES
  useEffect(() => {
    if (mySourcedError
        && shownErrorStack.findIndex(
          ({ timedError }) =>  sameNotUndefinedTimedErrors(timedError, mySourcedError)
        ) === -1
    ) {
      const errorActionMaker = errorDsiplayHandleGen({ attemptRecoveryFromError })
      const eta = errorActionMaker({ timedError: mySourcedError, source: errorSource });
      const finalEta = (errorToDismissActionOverride && errorToDismissActionOverride(eta)) || eta;
      show(finalEta);
      return;
    }
  }, [shownErrorStack, addOrRemoveSourcedError, attemptRecoveryFromError, errorSource, mySourcedError, show, errorToDismissActionOverride]);

};

export default useErrorManager;

import React, { useMemo } from "react";
import "./index.scss";

type GenreSelectProps<T> = {
  id?: string;
  value?: string;
  options: T[];
  extractor: (opt: T, index: number, array: T[]) => [string, string];
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
};

export default function Selector<T>({
  id,
  value,
  options,
  extractor,
  onChange,
}: GenreSelectProps<T>): JSX.Element {
  const opts = useMemo(() => options.map(extractor), [options]);

  return (
    <div className="Selector__Container">
      <select className="Selector__Select"
        id={id}
        onChange={onChange}
        value={value}
      >
        {opts.map(([key, value]) => (
          <option key={key} value={key}>
            {value}
          </option>
        ))}
      </select>
    </div>
  );
}

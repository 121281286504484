import { PreferenceConfig, PreferenceKey } from "../types/preferences";

export const PREFERENCES: PreferenceConfig = {
  [PreferenceKey.TOOLBAR_NAVIGATION]: {
    defaultValue: false,
    type: 'boolean'
  },
  [PreferenceKey.USE_TABS_NOT_DROPDOWN]: {
    defaultValue: false,
    type: 'boolean'
  },
  [PreferenceKey.USE_TOP_PAGINATION_SORT_FILTERS]: {
    defaultValue: false,
    type: 'boolean'
  },
  [PreferenceKey.LIMIT_TO_FILTERS_ONLY]: {
    defaultValue: true,
    type: 'boolean'
  },
  [PreferenceKey.THEME]: {
    defaultValue: 'light',
    type: 'string'
  },
  [PreferenceKey.FONT_SIZE]: {
    defaultValue: 16,
    type: 'number'
  },
};

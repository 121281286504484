import { useEffect, useRef } from "react";

const APP_ID = `WebPlayer_v${import.meta.env.VITE_APP_VERSION}`.replace(/[^a-zA-Z0-9_]/g, '_');

const getBrowserInfo = async (): Promise<string> => {
  if (window.navigator.userAgentData) {
    try {
      const { brands, platform, mobile } = await window.navigator.userAgentData.getHighEntropyValues([]);
      const lastBrand = brands[brands.length - 1];
      return `${lastBrand.brand}_v${lastBrand.version}_${platform}_${mobile ? '' : 'not_'}mobile`.replaceAll(' ', '_');
    } catch {}
  }
  return navigator.userAgent;
};

export const useClientInfo = () => {
  const clientInfoRef = useRef<string|null>(null);

  useEffect(() => {
    if (clientInfoRef.current) return;
    getBrowserInfo().then((browserInfo) => {
      clientInfoRef.current = `${APP_ID}__${browserInfo}`.replace(/[^a-zA-Z0-9_]/g, '_');
    });
  }, []);

  return clientInfoRef.current || APP_ID;
};

import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../Button";

type ComponentProps = {
  triggerSearch: () => void;
  disabled: boolean;
}

const SearchButton: React.FC<ComponentProps> = ({
  disabled,
  triggerSearch
}) => {
  const { t } = useTranslation(["common"]);
  return (
    <Button
      fullWidth
      onClick={(!disabled && ((e) => {
        e.currentTarget.blur();
        triggerSearch()}
      )) || undefined}
      aria-label={t("Search")}
      disabled={disabled}
    >
      {t("Search")}
    </Button>
  );
};

export default SearchButton;
export const PreferenceKey = {
  TOOLBAR_NAVIGATION: 'useToolbarNavigation',
  USE_TOP_PAGINATION_SORT_FILTERS: 'usePaginationSortFilters',
  LIMIT_TO_FILTERS_ONLY: 'limitToFiltersOnly',
  USE_TABS_NOT_DROPDOWN: 'useTabsNotDropdown',
  THEME: 'theme',
  FONT_SIZE: 'fontSize',
} as const;

export type PreferenceKeyType = typeof PreferenceKey[keyof typeof PreferenceKey];

export type PreferenceValueType = {
  defaultValue: any;
  type: 'boolean' | 'string' | 'number' | 'object';
}

export type PreferenceConfig = {
  [K in PreferenceKeyType]: PreferenceValueType
};
import { useCallback, useRef, useState } from 'react';
import { RequestStatus } from '../constants/apiStatus';
import { useApiContext } from '../contexts/ApiProvider';
import { l } from '../../utils/log';
import { CacheOptions } from '../utils/cache';

export enum BypassOption {
  all = 'all', // bypass existing pending requests (make a new request every time)
  cache = 'cache', // wait pending requests, bypass cached requests (make a new request if no cache)
  none = 'none', // wait pending requests, use cached requests
}

export type ExecOptions = {
  bypass?: BypassOption;
  cache?: CacheOptions;
};

export const defaultOptions: ExecOptions = {
  bypass: BypassOption.none,
  cache: {
    keepAliveFor: 5 * 60 * 1000,
  }
};

const ns = 'useApi';

// Helper to create a unique key for requests
const createRequestKey = (fnName: string, params: any) => {
  return `${fnName}:${JSON.stringify(params)}`;
};

export default function useApi<T>(
  fn: (args: { params: any; signal: AbortSignal }) => Promise<T>,
  setDataCallback?: (data: T, cacheTimestamp: number) => void,
  callerNs = ns
) {
  const finalNs = `${ns}:${callerNs}`;
  const log = l([finalNs]);
  const apiContext = useApiContext();
  const requestKeyRef = useRef<string | undefined>(undefined);
  const requestTimestampRef = useRef<number | undefined>(undefined);
  // Status drives the rerenders
  const [localStatus, setLocalStatus] = useState<RequestStatus>(RequestStatus.IDLE);
  const dataRef = useRef<T | null>(null);
  const errorRef = useRef<Error | null>(null);

  // useEffect(() => {
  //   return () => {
  //     if (requestKeyRef.current) {
  //       log("CLEANING UP requestKeyRef.current:", requestKeyRef.current);
  //       apiContext.clearRequest(requestKeyRef.current);
  //     }
  //   };
  // }, [apiContext]);

  const exec = useCallback(async (params: any, options: ExecOptions = defaultOptions) => {
    const key = createRequestKey(fn.name, params);
    log("EXEC key:", key, "bypass:", options.bypass, requestTimestampRef.current);
    requestKeyRef.current = key;

    // Check for cached dataRef.current when bypass is 'none'
    if (options.bypass === BypassOption.none && apiContext.hasCache(key)) {
      const { data, cache } = apiContext.getCachedData<T>(key);
      if (data) {
        log("EXEC using cached data for key:", key, requestTimestampRef.current);
        dataRef.current = data;
        setDataCallback?.(data, cache!.timestamp);
        setLocalStatus(RequestStatus.SUCCESS);
        return;
      }
    }

    const requestState = apiContext.registerRequest(key);

    // If there's a pending request and we're not using 'all' bypass, wait for it
    if (options.bypass !== BypassOption.all && requestState.promise) {
      log("EXEC pending for key:", key, requestTimestampRef.current);
      setLocalStatus(RequestStatus.PENDING);
      try {
        const result = await requestState.promise;
        log("EXEC succeeded for key:", key, requestTimestampRef.current);
        dataRef.current = result;
        setDataCallback?.(result, (new Date).getTime());
        setLocalStatus(RequestStatus.SUCCESS);
        return;
      } catch (err) {
        log("EXEC failed for key:", key, err, requestTimestampRef.current);
        errorRef.current = err instanceof Error ? err : new Error(String(err));
        setLocalStatus(RequestStatus.ERROR);
        return;
      }
    }

    // Only abort existing request if using 'all' bypass
    if (options.bypass === BypassOption.all && requestState.abortController) {
      log("EXEC aborting existing request for bypass all key:", key, requestTimestampRef.current);
      requestState.abortController.abort();
    }

    // Create new request
    setLocalStatus(RequestStatus.PENDING);
    const promise = fn({ params, signal: requestState.abortController.signal });
    const timeOfExec = (new Date()).getTime();
    requestTimestampRef.current = timeOfExec;
    log("EXEC created new request for key:", key, "set new timestamp", timeOfExec);

    apiContext.updateRequestState(key, { promise });

    try {
      const result = await promise;
      log("EXEC received result for key:", key, timeOfExec);
      dataRef.current = result;
      setDataCallback?.(result, (new Date()).getTime());
      setLocalStatus(RequestStatus.SUCCESS);
      log("EXEC caching result for key:", key, timeOfExec);
      apiContext.cacheResult(key, result, options.cache);
    } catch (err) {
      errorRef.current = err instanceof Error ? err : new Error(String(err));
      log("EXEC encountered errorRef.current for key:", key, errorRef.current, timeOfExec);
      setLocalStatus(RequestStatus.ERROR);
      apiContext.updateRequestState(key, {
        status: RequestStatus.ERROR,
        error: errorRef.current,
      });
    }
  }, [fn, setDataCallback]);

  const clearHook = useCallback(() => {
    log("CLEARING API", requestKeyRef.current, requestTimestampRef.current);
    setLocalStatus(RequestStatus.IDLE);
    errorRef.current = null;
    dataRef.current = null;
  }, []);

  const abort = useCallback(() => {
    if (requestKeyRef.current) {
      log("ABORTING request for key:", requestKeyRef.current, requestTimestampRef.current);
      apiContext.clearRequest(requestKeyRef.current);
    }
  }, []);

  const clearApi = useCallback(() => {
    log("clearApi called");
    clearHook();
    abort();
  }, [clearHook, abort]);

  log("RENDERING with status:", localStatus, "dataRef.current:", dataRef.current, "errorRef.current", errorRef.current, " timestamp", requestTimestampRef.current);

  return {
    data: dataRef.current,
    apiStatus: localStatus,
    error: errorRef.current,
    exec,
    clearHook,
    clearApi,
    abort,
    isPending: localStatus === RequestStatus.PENDING,
    isSuccess: localStatus === RequestStatus.SUCCESS,
    isError: localStatus === RequestStatus.ERROR,
    isIdle: localStatus === RequestStatus.IDLE,
    finalNs,
  };
}
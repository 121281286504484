import { Genre } from "../../types/search";
import api, { AbortControllerRef, getRefAbortControllerSetter } from "../api";
import { ApiResponse } from "./types";

export type ListOfGatewayGenresParams = {
  origin?: string;
};

type ListOfGatewayGenresResponse =
  ApiResponse<{ ListOfGatewayGenres: Genre[]; }>;

export const getGenres = async ({ abortRef }: {
  params: undefined,
  abortRef?: AbortControllerRef;
}): Promise<Genre[]> => {
  const res = await api.get<ListOfGatewayGenresResponse>("/", {
    ...getRefAbortControllerSetter(abortRef),
    params: {
      funcName: "ListOfGatewayGenres",
      origin: import.meta.env.VITE_RESULTS_ONLY_FROM_ORIGIN,
    },
  });

  if (res?.data?.result?.ListOfGatewayGenres === undefined) {
    if (res?.data?.extra) {
      throw Error(res.data.extra);
    }
    throw new Error("Bad Response");
  }

  return res.data.result.ListOfGatewayGenres;
};

export default getGenres;

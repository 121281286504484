import { useCallback } from "react";
import { AbortControllerRef } from "../api/api";
import useResultBase, { ABTest, AuthStyle, UseResultReturnBase } from "./useResultBase";

type UseResultReturn<
  F extends (arg0: { params: any; abortRef?: AbortControllerRef }) => any,
  P extends keyof Awaited<ReturnType<F>> & string
> = {
  [K in P]: Awaited<ReturnType<F>>[P] | null;
} & Omit<UseResultReturnBase<F, (data: Awaited<ReturnType<F>>) => Awaited<ReturnType<F>>[P]>, "result">;

function useResult<
  F extends (arg0: { params: any; abortRef?: AbortControllerRef }) => any,
  P extends keyof Awaited<ReturnType<F>> & string
>(
  fetchResult: F,
  dataProp: P,
  paramsAreSame: ABTest<Parameters<F>[0]["params"]>,
  authStyle: AuthStyle,
  initialParams: Parameters<F>[0]["params"] | null = null,
  callerNs = "useResult"
): UseResultReturn<F, P> {
  // Define the transform function to extract the specified property
  const transform = useCallback(
    (data: Awaited<ReturnType<F>>) => data[dataProp],
    [dataProp]
  );

  const { result, ...rest } = useResultBase(
    fetchResult,
    transform,
    paramsAreSame,
    {
      authStyle,
      initialParams,
      callerNs,
    }
  );

  return {
    ...rest,
    [dataProp]: result,
  } as UseResultReturn<F, P>;
}

export default useResult;
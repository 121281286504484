import React from 'react';
import './index.scss';

const FAQLinks = ({ variant = 'onDark' }: { variant: 'onWhite' | 'onDark' }) => {
  return (
    <nav className={`FAQ__Links FAQ__Links--${variant}`}>
      <ul>
        <li>
          <a href="/faq#inscription">Comment s'inscrire à la BBR ?</a>
        </li>
        <li>
          <a href="/faq#connexion">Comment se connecter au catalogue ?</a>
        </li>
        <li>
          <a href="/faq#telechargement">Comment télécharger un livre ?</a>
        </li>
        <li>
          <a href="/faq#ecoute-daisy">Comment écouter un livre audio au standard DAISY ?</a>
        </li>
        <li>
          <a href="/faq#lecture-braille">Comment lire un ouvrage en braille numérique ?</a>
        </li>
        <li>
          <a href="/faq#peripherique-braille">Où se procurer un périphérique spécifique pour la lecture du braille ?</a>
        </li>
      </ul>
    </nav>
  );
};

export default FAQLinks;
import React, { useContext, useEffect } from "react";
import useBookSearch from "src/hooks/useBookSearch";
import Pagination from "../Pagination";
import SearchContext from "../../contexts/Search/SearchContext";
import useErrorManager from "../../hooks/useErrorManager";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Books from "../Books";
import { useLocation } from "react-router-dom";
import { doParamsHaveAppliedFilters } from "../../utils/search";
import { l } from "../../utils/log";
import { createHumanReadableSentence } from "../../utils/searchToHumanReadable";
import useGenres from "../../hooks/useGenres";
import ResultsToolbar from "../ResultsToolbar";
import ResultsControls from "../ResultsControls";
import { PreferenceKey } from "../../types/preferences";
import { useStoragePreference } from "../../contexts/Preferences/PreferencesProvider";

type ComponentProps = {}


const ns = "BookResults";
const log = l(ns);
const booksContentId = "books-main-section";

const BookResults: React.FC<ComponentProps> = ({}) => {
  const queryParams = useContext(SearchContext);
  const { genres } = useGenres();
  const { queryText } = queryParams;

  const location = useLocation();

  const {
    books,
    isPending,
    apiStatus,
    totalCount,
    receivedCount,
    attemptRecoveryFromError,
    currentBooksValuesParam,
    page,
  } = useBookSearch(ns);

  useEffect(() => {
    log('books changed', books);
  }, [books]);
  useEffect(() => {
    log('queryParams changed', queryParams);
  }, [queryParams]);
  useEffect(() => {
    log('genres changed', genres);
  }, [genres]);
  useEffect(() => {
    log('isPending changed', isPending);
  }, [isPending]);
  useEffect(() => {
    log('apiStatus changed', apiStatus);
  }, [apiStatus]);
  useEffect(() => {
    log('totalCount changed', totalCount);
  }, [totalCount]);
  useEffect(() => {
    log('currentBooksValuesParam changed', currentBooksValuesParam);
  }, [currentBooksValuesParam]);
  useEffect(() => {
    log('page changed', page);
  }, [page]);

  log(apiStatus, books);
  log("Page", page)
  log("CurrentBooksVP", currentBooksValuesParam)

  useErrorManager(ns, attemptRecoveryFromError);

  const { t } = useTranslation(["common"]);

  const isHome = !location.pathname.includes('/search');

  log(createHumanReadableSentence(books?.length || 0, queryParams, genres, t));

  useDocumentTitle(`${!isHome
    ? `${(
      (queryText && queryText !== "")
        ? `${t("Results for")}: "${queryText}"`
        : t("Books")
      )}${doParamsHaveAppliedFilters(queryParams)
        ? ` (${t("filters active")})`
        : ""
    }`
    : t("Home Page")}`
  );

  const start = page * parseInt(import.meta.env.VITE_RESULTS_PER_PAGE);

  const { preference: showToolbar } = useStoragePreference<boolean>(PreferenceKey.TOOLBAR_NAVIGATION);
  const { preference: isUseTopPaginationSortFilters } = useStoragePreference<boolean>(PreferenceKey.USE_TOP_PAGINATION_SORT_FILTERS);

  return (
    <>
      {(isUseTopPaginationSortFilters && (
        (showToolbar && (
          <ResultsToolbar aria-controls={booksContentId} />
        )) || <ResultsControls aria-controls={booksContentId} />
      )) || null}
      <Books
        containerId={booksContentId}
        title={
          !isPending
            ? `${isHome || typeof queryText === "undefined"
                ? t("Latest published books")
                : `${t("Search<recherche>")} "${queryText}"`
              }: ${receivedCount === 0 ? t("none found") : t("results {{start}} to {{end}} of {{total}}", { start: start + 1, end: start + receivedCount, total: totalCount})}`
            : t("Loading book results")
        }
        noBooksMessage={
          (isHome && (
            t("No books yet.")
          )) || (
            `${t("No ")} ${createHumanReadableSentence(books?.length || 0, queryParams, genres, t)}`
          )
        }
        books={books}
        isPending={isPending}
        apiStatus={apiStatus}
      />
      <Pagination />
    </>
  );
}

export default BookResults;
import React from 'react';

interface PaginationInfoProps {
  currentPage: number;
  totalPages: number;
  inToolbox?: boolean;
  t: (key: string, props: { [k: string]: string|number }) => string;
}

export const PaginationInfo: React.FC<PaginationInfoProps> = ({
  currentPage,
  totalPages,
  inToolbox = false,
  t
}) => {
  return (
    <h3
      aria-hidden={inToolbox || undefined }
      className="Pagination__Info"
    >
      {t("Page {{currentPage}} of {{totalPages}}", { currentPage, totalPages })}
    </h3>
  );
};
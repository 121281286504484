import React, { useContext, useEffect, useRef } from 'react';
import { IoAccessibility } from "react-icons/io5";
import './index.scss';
import { useTranslation } from 'react-i18next';
import { useQuickLinks } from '../../contexts/QuickLinks/QuickLinksProvider';
import { LinkableElementId, LinkPriority, LinksDisplayRegion } from '../../contexts/QuickLinks/QuickLinksContext';
import SearchProvider from '../../contexts/Search/SearchProvider';
import { SearchQueryProvider } from '../../contexts/SearchQuery/SearchQueryProvider';
import ErrorModal from "../../components/ErrorModal";
import ErrorContext from '../../contexts/Error/ErrorContext';
import { FocusNeededProvider } from '../../contexts/FocusNeeded/FocusNeededProvider';
import { AccessibilityToolbar } from 'a11y-button';
import 'a11y-button/dist/index.css';
import Button from '../Button';
import { l } from '../../utils/log';
import QuickLinks from '../QuickLinks';

type ComponentProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  children?: React.ReactNode;
  header: React.ReactNode;
  onAccessibilityClicked?: () => void,
  main: React.ReactNode;
  footer: React.ReactNode;
};

const log = l('PageComponent');

export default function Page({
  children = null,
  header,
  onAccessibilityClicked,
  main,
  footer,
  ...rest
}: ComponentProps) {
  const { t } = useTranslation(['common']);
  const { links, addLinks, removeLink } = useQuickLinks();
  const pageContainerRef = useRef<HTMLDivElement | null>(null);
  const { shownErrorStack } = useContext(ErrorContext);
  const [shownError] = shownErrorStack;

  useEffect(() => {
    const defaultLinks = [
      {
        id: LinkableElementId.pageMain,
        href: `#${LinkableElementId.pageMain}`,
        label: 'Go to main content',
        priority: LinkPriority.mainContent,
      },
      {
        id: LinkableElementId.pageHeader,
        href: `#${LinkableElementId.pageHeader}`,
        label: 'Go to header',
        priority: LinkPriority.average,
      },
      {
        id: LinkableElementId.pageFooterFAQ,
        href: `${LinkableElementId.pageFooterFAQ}`,
        label: 'Go to help page',
        priority: LinkPriority.average,
      },
      {
        id: LinkableElementId.pageFooterContact,
        href: `#${LinkableElementId.pageFooterContact}`,
        label: 'Go to contact info',
        priority: LinkPriority.average,
      },
      {
        id: LinkableElementId.pageFooter,
        href: `#${LinkableElementId.pageFooter}`,
        label: 'Go to footer',
        priority: LinkPriority.lowest,
      },
    ];

    addLinks(defaultLinks.map(link => ({
      link,
      namespace: LinksDisplayRegion.pageComponentTop
    })));

    return () => {
      defaultLinks.forEach((link) => removeLink({ id: link.id, namespace: LinksDisplayRegion.pageComponentTop }));
    };
  }, [addLinks, removeLink]);

  useEffect(() => {
    log('Pre-footer useEffect running');
    const defaultLinks = [
      {
        id: LinkableElementId.pageMain,
        href: `#${LinkableElementId.pageMain}`,
        label: 'Go back to content start',
        priority: LinkPriority.mainContent,
      },
      {
        id: LinkableElementId.accessibilityToolbar,
        href: `#${LinkableElementId.accessibilityToolbar}`,
        label: 'Go to accessibility toolbar',
        priority: LinkPriority.lowest,
      },
    ];

    log('Adding pre-footer links:', defaultLinks);
    addLinks(defaultLinks.map(link => ({
      link,
      namespace: LinksDisplayRegion.pageComponentPreFooter
    })));

    log('Current links state:', links);

    return () => {
      defaultLinks.forEach((link) => removeLink({ id: link.id, namespace: LinksDisplayRegion.pageComponentPreFooter }));
    };
  }, [addLinks, removeLink]);

  // Map to store original values of focusable elements that are modified
  const focusableElementsOriginalState = useRef(new Map<HTMLElement, { tabIndex: string | null; ariaDisabled: string | null; pointerEvents: string }>());

  useEffect(() => {
    if (pageContainerRef.current && shownError) {
      const focusableElements = pageContainerRef.current.querySelectorAll(
        'a, button, input, textarea, select, [tabindex]'
      ) as NodeListOf<HTMLElement>;

      // Disable elements only when the error is shown
      focusableElements.forEach((el) => {
        if (!focusableElementsOriginalState.current.has(el)) {
          // Store original values if they haven't been stored yet
          focusableElementsOriginalState.current.set(el, {
            tabIndex: el.getAttribute('tabindex'),
            ariaDisabled: el.getAttribute('aria-disabled'),
            pointerEvents: el.style.pointerEvents || '',
          });

          // Disable the elements
          el.setAttribute('tabindex', '-1');
          el.setAttribute('aria-disabled', 'true');
          el.style.pointerEvents = 'none';
        }
      });
    }

    if (!shownError && focusableElementsOriginalState.current.size > 0) {
      // If shownError becomes false, restore the original values
      focusableElementsOriginalState.current.forEach((originalState, el) => {
        // Restore original tabindex
        if (originalState.tabIndex !== null) {
          el.setAttribute('tabindex', originalState.tabIndex);
        } else {
          el.removeAttribute('tabindex');
        }

        // Restore original aria-disabled
        if (originalState.ariaDisabled !== null) {
          el.setAttribute('aria-disabled', originalState.ariaDisabled);
        } else {
          el.removeAttribute('aria-disabled');
        }

        // Restore original pointer-events
        el.style.pointerEvents = originalState.pointerEvents;
      });

      // Clear the map once restoration is done
      focusableElementsOriginalState.current.clear();
    }
  }, [shownError]);

  return (
    <SearchProvider>
      <SearchQueryProvider>
        <FocusNeededProvider>

          <div className="Page">
            <div
              ref={pageContainerRef}
              className="Page__Container"
              {...rest}
            >
              <QuickLinks namespace={LinksDisplayRegion.pageComponentTop} />
              <div className="Accessibility__Container">
                <AccessibilityToolbar
                  hideButtonWhenOpen={true}
                  id={LinkableElementId.accessibilityToolbar}
                  languages={['fr']}
                >
                  <Button
                    icon={<IoAccessibility />}
                    size="sm"
                    variant="outline"
                    className="clickable"
                    noMargins
                    onClick={() => {
                      setTimeout(() => {
                        window.document.getElementById(LinkableElementId.accessibilityToolbar)?.focus();
                        onAccessibilityClicked && onAccessibilityClicked();
                      }, 300);
                    }}
                  >
                    {t("Accessibility")}
                  </Button>
                </AccessibilityToolbar>
              </div>
              <header role="banner" id={LinkableElementId.pageHeader}>
                {header}
              </header>
              <main className="Page__Main" role="main" id={LinkableElementId.pageMain}>
                {main}
              </main>
              <QuickLinks namespace={LinksDisplayRegion.pageComponentPreFooter} />
              <footer role="contentinfo" id={LinkableElementId.pageFooter}>
                {footer}
              </footer>
            </div>
            <ErrorModal />
            {children}
          </div>
        </FocusNeededProvider>
      </SearchQueryProvider>
    </SearchProvider>
  );
}